import Layout from "components/Layouts/Layout";
import React, { useContext, useState } from "react";
import { ScreenContext } from "components/ScreenContext";
import { InertiaLink, useForm } from "@inertiajs/inertia-react";
import Modals, { MODALS, ModalsContext } from "app/components/Layouts/Modals";
import ProductDetailLanding from "app/components/ProductDetailLanding";
import SpecPozadavky from "app/components/SpecPozadavky";
import KontaktGrid2 from "app/components/KontaktGrid2";
import Svg from "app/components/Svg";
import Socials from "app/components/Socials";
import usePageProps from "app/components/hooks/usePageProps";


interface Props{
  className?: string,
}


export default function Home(props: Props) {
  const { product_detail } = usePageProps<{ product_detail: { data: any } }>();
  const { isMobile } = useContext(ScreenContext);
  const {open} = useContext(ModalsContext)
  const form = useForm({});
  const {data} = form;
  return (
    <Layout>
      <div className="bg-bg w-full h-full">
        <div className="mobile:hidden relative w-full h-full">
          <Svg src="/icons/loglines5" className="mt-280px absolute left-0 top-0 z-0"/>
        </div>
        <ProductDetailLanding product={product_detail} />
        <KontaktGrid2 />
        <SpecPozadavky />
        <Socials />
      </div>
    </Layout>
  );
}