import React, { useState, useEffect, useRef } from 'react';
import Button from './Buttons/Button';
import ImgJpg from './ImgJpg';
import ImgSvg from './ImgSvg';
import Svg from './Svg';

export default function PribehLanding(){

  return(
    <>
      <div className="mobile:hidden relative w-full h-full opacity-16">
          <Svg src="/icons/loglines6" className="mt-736px absolute right-0 top-0 z-0"/>
      </div>

      <div className="notMobile:py-100px relative mobile:py-16px ">
        <div className="flex justify-between items-center mobile:flex-col-reverse mx-16px notMobile:mx-160px">
          <div className="flex flex-col  max-w-lg">
            <div className="text-darkBrown text-h32 dmserif">
            Lignum Nostri - těší nás!
            </div>
            <div className=" mt-24px mb-32px notMobile:w-95%">
            Jsme mladí lidé, které&nbsp;spojuje nejen láska partnerská, ale&nbsp;i&nbsp;ta&nbsp;ke&nbsp;dřevu. Náš plán byl jasný - začneme se&nbsp;dřevu a&nbsp;jeho zpracování aktivně věnovat. Ruku na&nbsp;srdce, jaká práce může být lepší než ta, co&nbsp;člověka baví?
            </div>
            <div className="w-divider border-t border-dividerGold mb-32px mobile:hidden"/>
          </div>
          <ImgJpg src="team" className=" max-w-550px 2560min:max-w-5xl mobile:max-w-327px mobile:mr-16px "/>
        </div>
      </div>

      <div className="notMobile:my-100px relative mobile:py-16px ">
        <div className="flex justify-between items-center mobile:flex-col mx-16px notMobile:mx-160px">
          <ImgSvg src="sawmill" className=" max-w-550px w-full 2560min:max-w-5xl mobile:max-w-327px mobile:mr-16px"/>
          <div className="flex flex-col  max-w-lg">
            <div className=" mt-24px mb-32px">
            Láska ke&nbsp;dřevu ale&nbsp;nebyla jediný důvod, proč jsme se&nbsp;rozhodli vzít věci do&nbsp;vlastních rukou. Nebyli jsme už&nbsp;spokojeni s&nbsp;nákupem nábytku od&nbsp;franchisových společností a&nbsp;chtěli jsme vytvořit něco, čemu&nbsp;společným úsilím vdechneme duši a&nbsp;co bude dělat radost nejen nám, ale&nbsp;i&nbsp;ostatním.
            </div>
          </div>
        </div>
      </div>

      <div className="notMobile:my-100px relative mobile:py-16px ">
        <div className="flex justify-between items-center mobile:flex-col-reverse mx-16px notMobile:mx-160px">
          <div className="flex flex-col  max-w-lg">
            <div className=" mt-24px mb-16px">
            A tak jsme tu. Vymýšlíme, ladíme a&nbsp;vyrábíme produkty pro&nbsp;lidi na stejné vlně - pro lidi, kteří&nbsp;chtějí tvořit a&nbsp;doplňovat svůj domov dřevěnými kousky s&nbsp;nádechem přírody vyrobené někým, koho to&nbsp;zkrátka a&nbsp;dobře opravdu baví.
            </div>
            <div className="">
            Přidejte se k nám!
            </div>
            <div className="mt-16px">
            Vaši Adam & Julie
            </div>
          </div>
          <ImgJpg src="varnishing" className=" max-w-550px w-full 2560min:max-w-5xl mobile:max-w-327px mobile:mr-16px"/>
        </div>
      </div>
    </>
  )
};