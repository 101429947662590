import { useForm } from '@inertiajs/inertia-react';
import Form from 'app/fragments/Forms/Form';
import Select from 'app/fragments/Forms/Select';
import TextField from 'app/fragments/Forms/TextField';
import { post } from 'jquery';
import React, { useState, useEffect, useRef } from 'react';
import Svg from './Svg';
import Button from './Buttons/Button';
import Checkbox from 'app/fragments/Forms/Checkbox';
import TextArea from 'app/fragments/Forms/TextArea';
import route from 'ziggy-js';
import Select2 from 'app/fragments/Forms/Select2';

export default function KontaktGrid2(){

  const form = useForm({});
  const {data, post} = form;

  const submit = (e) => {
    e.preventDefault()
    post(route('contact'),{
      preserveScroll: true,
      onError: e => console.log(e),
      onSuccess: () => {window.scrollTo({top: 0, behavior: "smooth"});}
    })
  }

  return(
    <>
      <div id="demand" className="grid grid-cols-2 mobile:grid-cols-1 grid-rows-1 h-868px mobile:h-750px w-full">
        <div className="bg-darkGreen text-dividerGold">
          <div className="flex flex-col  items-center notMobile:mt-88px mobile:mt-48px">
            <div className="text-24 dmserif mobile:w-mobileFull mobile:flex justify-start mb-16px">
              Zanechte nám kontakt
            </div>
            <div className="text-16 mobile:max-w-mobileFull mb-16px max-w-448px">
            Ozveme se zpět, ke&nbsp;každému přistupujeme individuálně.
            </div>
            <Form form={form} onSubmit={submit} className="max-w-448px w-full mobile:max-w-mobileFull text-14 text-lignumText">
              <TextField name="name" label={<span>Celé jméno<span className="text-redred">*</span></span>} placeholder="např. Jan Novák" />
              <TextField className="mb-16" name="email" label={<span>E-mail<span className="text-redred">*</span></span>} placeholder="vas@mail.cz" />
              <div className="flex items-end mb-16">
              <Select2 color='darkGreen' name="code" label={<span>Telefon<span className="text-redred">*</span></span>} className="w-60px" options={[
                {text: "+420", value:"+420"},
                {text: "+421", value:"+421"},
              ]} placeholder="Předvolba" defaultValue="+420" />
              <div className="w-20px" />
              <TextField className='mb-4px' name="phone" label={""} placeholder="telefonní číslo" />
              </div>
              <TextArea className="mb-16 bg-darkGreen" name="message" label="Zpráva" placeholder=""/>
              <Checkbox className="mb-16" name="agree" target="_blank" label={<span>Souhlasím se <a href="/assets/gdpr_lignumnostri.pdf" className="underline font-semibold">zpracováním osobních údajů</a><span className="text-redred">*</span></span>} />
              <Checkbox className="mb-16" name="newsletter" label="Odebírat newsletter" />
              <Button href="/success" onClick={submit} className="uppercase mobile:w-full h-56px bg-darkGreen hover:text-darkGreen border-dividerGold"> ODESLAT </Button>
            </Form>
          </div>
        </div>
        <div className="relative mobile:hidden">
          <div className="absolute inset-0 w-full h-full object-cover">
            <img src="/assets/img/woodcut.png" className="object-cover w-full h-full"/>
          </div>
          <Svg src="/icons/loglines4" className="absolute bottom-0 left-0 col-start-1"/>
        </div>
      </div>
    </>
  )
};