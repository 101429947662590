import React, { useState, useEffect, useRef } from 'react';
import Button from './Buttons/Button';
import Svg from './Svg';

export default function ZakazkovaVyroba2(){

  return(
    <>
      <div className="h-472px mobile:h-561px w-full relative 390:mb-30px mb-130px notMobile:mb-0">

        <div className="absolute top-0 w-full bg-dividerGold h-50px mobile:hidden"/>

        <div className="absolute flex justify-evenly mobile:flex-col notMobile:items-center w-full mobile:mx-16px mobile:max-w-mobileFull ">
          <div className="flex flex-col z-10 max-w-448px   mobile:max-w-mobileFull">
            <div className="dmserif text-24 text-lightGold mb-16px mobile:mt-48px">
              Zakázková výroba
            </div>
            <div className="text-16 text-lightGold mb-24px max-w-448px ">
            Nenašli jste, co&nbsp;jste hledali? Máte svou vlastní představu, které&nbsp;byste chtěli vdechnout život? Žádný problém - rádi&nbsp;Vám vytvoříme produkt na&nbsp;míru, nejprve&nbsp;však spolu vše nezávazně zkonzultujeme.
            </div>
            <Button href="/vyroba" className="uppercase hover:bg-lightGold w-325px h-56px bg-darkBrown hover:text-darkGreen border-lightGold text-lightGold mobile:max-w-mobileFull mobile:mb-29px">
              VÍCE O ZAKÁZKOVÉ VÝROBĚ
            </Button>
          </div>

          <div className="grid grid-cols-2 grid-rows-2 max-w-581px mobile:max-h-286px">
            <div className="col-start-1 row-start-1 col-span-2 row-span-2 w-5/6 h-5/6 max-w-maxWPlanks relative">
              <div className="absolute w-full h-full left-0 top-0 border border-mediumGold ml-16px mt-16px mobile z-10 rounded-sm"/>
              <img src="/assets/img/planks.png" className="z-20 relative object-cover h-full w-full rounded-sm"/>
            </div>
            <div className="col-start-2 row-start-2 pr-20px">
              <div className=" max-w-maxWSawdust relative">
                <div className="absolute w-full h-full left-0 top-0 border border-mediumGold ml-16px mt-16px mobile z-10 rounded-sm"/>
                <img src="/assets/img/sawdust.png" className=" z-20 relative object-cover h-full w-full rounded-sm"/>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute h-376px mobile:h-502px w-full notMobile:mt-40px bg-darkBrown mb-55px z-0">
          <Svg src="/icons/loglines2" className="absolute left-0 mobile:hidden"/>
          <Svg src="/icons/loglines3" className="absolute left-0 notMobile:hidden"/>
        </div>

      </div>
    </>
  )
};