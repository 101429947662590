import Layout from "components/Layouts/Layout";
import React, { useContext, useState } from "react";
import { ScreenContext } from "components/ScreenContext";
import { InertiaLink, useForm } from "@inertiajs/inertia-react";
import Modals, { MODALS, ModalsContext } from "app/components/Layouts/Modals";
import Svg from "app/components/Svg";
import SawmillLanding from "app/components/SawmillLanding";
import ZakazkovaVyroba from "app/components/ZakazkovaVyroba";
import NasPribeh from "app/components/NasPribeh";
import Socials from "app/components/Socials";
import SelectProductType from "app/fragments/SelectProductType";
import KontaktGrid2 from "app/components/KontaktGrid2";

export interface Category {
  id: number, 
  name:string, 
  icon: string
}

interface Props{
  className?: string,
  categories: Array<Category>
}




export default function Home(props: Props) {
  const { isMobile } = useContext(ScreenContext);
  const {open} = useContext(ModalsContext)
  const form = useForm({});
  const {data} = form;
  return (
    <Layout>
      <div className="bg-bg w-full h-full">
        <div className="mobile:hidden relative w-full h-full">
          <Svg src="/icons/loglines5" className="mt-280px absolute left-0 top-0 z-0"/>
        </div>
        <SawmillLanding />
        <div className="w-full border-t border-dividerGold mt-32px notMobile:hidden"/>
        <SelectProductType />
        <ZakazkovaVyroba />
        <NasPribeh />
        <KontaktGrid2 />
        <Socials />
      </div>
    </Layout>
  );
}