import React, { useState, useEffect, useRef } from 'react';
import Button from './Buttons/Button';
import Img from './Image';
import ImgSvg from './ImgSvg';

export default function VyrobaLanding(){

  return(
    <>
      <div className=" relative">
        <div className=" mobile:py-16px notMobile:py-100px flex justify-between items-center mobile:flex-col-reverse mx-16px notMobile:mx-160px">

          <div className="flex flex-col  max-w-lg relative pb-32px mobile:pb-24px">
            <div className="text-h32 dmserif notMobile:w-90%">
            Jsme Lignum Nostri - dva lidé, které spojuje láska ke dřevu <div className="">a poctivému řemeslu.</div>
            </div>
            <div className=" mt-24px mb-32px">
            Nenašli jste, co jste hledali? Máte vlastní představu, kterou byste rádi zrealizovali? Stačí říct. Vše spolu nezávazně zkonzultujeme a my vám výrobek rádi naceníme.
            </div>
            <Button href="#contact" className="uppercase w-250px bg-lightGold hover:bg-mediumGold hover:border-mediumGold hover:text-white mobile:w-full text-darkGreen z-50" >
              odeslat poptávku
            </Button>
            <div className="absolute mobile:hidden flex justify-end h-full w-divider border-b border-dividerGold opacity-16 mb-32px"/>
          </div>

          <div className="max-w-550px 2560min:max-w-5xl w-full mobile:mr-16px ml-16px mobile:hidden notMobile:mr-20px">
            <div className={" relative "}>
              <div className="absolute w-full h-full left-0 top-0 ml-20px mt-20px border bg-darkBrown border-mediumGold"/>
              <Img src={`/assets/img/team.jpg`} className="z-10 relative object-contain h-full w-full"/>
            </div>
          </div>
        </div>

        <div className="notMobile:hidden absolute border-b border-dividerGold opacity-16"/>  

        <div className="flex flex-col notMobile:items-center w-full h-full mobile:max-w-mobileFull max-w-lg mx-16px notMobile:mx-auto">
            <div className="dmserif text-24">
              Jak to u nás funguje?
            </div>
            <div className="notMobile:text-center">
            Zakládáme si na lidském přístupu a osobním kontaktu. Prohlédněte si, co vás s námi čeká.
            </div>
        </div>
      </div>
    </>
  )
};