import React from 'react'

interface Props {}

function MobileHeader(props: Props) {
    const {} = props

    return (
        <div></div>
    )
}

export default MobileHeader
