import Icon from 'components/Icon'
import React, { HTMLProps, useContext } from 'react'
import { FormContext } from './Form'

interface Props extends HTMLProps<HTMLInputElement> {
    label?: string | any
}

function Checkbox(props: Props) {
    const { label, className, name = "checkbox", ...rest } = props
    const { data, setData, errors, clearErrors } = useContext(FormContext)

    return (
        <label className={`relative cursor-pointer ${className}`} >
            <div className="flex ">
                <input
                    className="h-0 w-0 opacity-0 absolute"
                    {...rest}
                    type="checkbox"
                    name={name}
                    value={data[name] ?? ""}
                    onChange={e => {setData(name, e.target.checked); clearErrors(name)}}
                />
                <div className="border flex-shrink-0 border-dividerGold rounded-sm w-24px h-24px bg-darkGreen radio flex items-center justify-center relative" >
                    {/* <div className="bg-darkGreen w-24px h-22px rounded-sm" /> */}
                    <Icon className={data[name]!=true?"hidden":"" + (" absolute icon text-white h-18px")} name="tick" />
                </div>
                <div className="ml-8px leading-6">{label}</div>
            </div>
            <div className={`h-12px text-12 text-redred text-opacity-70 w-full flex justify-end ${errors[name] ? "" : "opacity-0"}`}>{errors?.[name]}</div>
        </label>
    )
}

export default Checkbox
