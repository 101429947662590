import Button from 'app/components/Buttons/Button'
import Icon from 'app/components/Icon'
import { ScreenContext } from 'app/components/ScreenContext'
import Svg from 'app/components/Svg'
import React, { useContext, useState } from 'react'

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Img from 'app/components/Image'


interface TileProps {
    name: string
    icon: string
    selectedTile: string
    setSelectProduct: Function
}

interface ProductProps {
    name: string,
    category: string,
    icon: string,
    img: string,
}

function ProductCardTest(props: ProductProps) {
    const { name, category, icon, img } = props
    return (
        <div className="h-full max-h-319px min-h-267px w-full mobile:mx-16px rounded-sm z-10 mt-48px">
            <div className="grid grid-cols-1 grid-rows-1 mr-40px z-10">
                <div className="col-start-1 row-start-1 ml-20px mt-20px w-full h-full border border-mediumGold rounded-sm bg-brown">
                    <div className="relative w-full h-full z-10">
                        <div className="flex justify-between items-end h-full w-full mx-16px pb-16px z-10 mt-200px">
                            <div className=" text-16 z-10">{name}</div>
                            <Svg src={("/icons/") + (icon)} className=" h-24px mx-40px z-10 flex-shrink-0" />
                        </div>
                    </div>
                </div>
                <div className=" col-start-1 row-start-1 rounded-sm">
                    <img src={("/assets/img/") + (img)} alt="" className="w-full pb-full h-85% 1920min:h-85% object-cover rounded-sm" />
                </div>
            </div>
        </div>
    )
}

function ProductCard(props: ProductProps) {
    const { name, category, icon, img } = props
    return (
        <div className="ml-20px mt-16px">
            <div className=" w-full h-full border border-mediumGold">
                <div className="rounded-sm  max-h-263px h-263px relative">
                    <Img src={("/assets/img/") + (img)} alt="" className="absolute top-0 left-0 w-full object-cover max-h-263px h-263px object-center rounded-sm -ml-20px -mt-16px z-50" />
                </div>
                <div className="flex mt-16px mx-16px mb-16px justify-between">
                    <div>{name}</div>
                    <Svg src={("/icons/") + (icon)} className=" h-24px  z-10 flex-shrink-0 ml-21px" />
                </div>
            </div>
        </div>
    )
}

let products = [
    { name: "Komoda pod televizi s posuvnými dvířky ze starého recyklovaného dřeva, doplněná o kovový posuv.", category: "minule", icon: "burger", img: "komoda-televize.jpg" },
    { name: "Komoda z borového dřeva namořená na tmavě hnědý odstín.", category: "minule", icon: "burger", img: "komoda-tmava.JPG" },
    { name: "Selský jídelní stůl z dubového dřeva, vhodný dovnitř i na zahradu.", category: "minule", icon: "burger", img: "selsky-stul.JPG" },
    { name: "Selské posuvné dveře z borového dřeva, namořené na hnědý odstín, doplněné o kovové posuvy a madla.", category: "minule", icon: "burger", img: "dvere.JPG" },
    { name: "Posuvné dveře z borového dřeva, namořené na hnědý odstín, doplněné o industriální kovové posuvy a madla.", category: "minule", icon: "burger", img: "tmave-dvere.jpg" },
    { name: "Jídelní stůl z dubového dřeva, doplněný o přírodní hranu a kovové podnože.", category: "minule", icon: "burger", img: "jidelni-stul.jpg" },
    { name: "Industriální stolky pod umyvadlo s deskou z jednoho kusu dubového dřeva, doplněné o kovové podnože.", category: "minule", icon: "burger", img: "industrialni-stolek.jpg" },
    { name: "Kovová police s odkládacím stolkem s deskou z jednoho kusu dubového dřeva, doplněné o kovové podnože.", category: "minule", icon: "burger", img: "kovova-police.jpg" },
]

interface Props { }

function SelectCarousel(props: Props) {
    const { } = props

    let [selectedProduct, setSelectProduct] = useState("minule")
    let { isMobile } = useContext(ScreenContext)

    let productCategories = [
        { productCategory: "minule", icon: "placeholder" },
    ]


    let [showMore, setShowMore] = useState(false)

    return (
        <div className="relative w-full">
            <div className="absolute top-50% w-full border-t border-dividerGold opacity-16 mobile:hidden" />
            <div className="px-72px mobile:px-0">
                <div className="notMobile:p-88px notMobile:bg-brown notMobile:border border-mediumGold relative mobile:px-16px">
                    <div className="text-24 dmserif pb-16px" >Produkty, které jsme vyráběli na zakázku</div>
                    <div className="grid">
                        <div className="col-start-1 row-start-1 flex justify-between ">
                            <div className="-ml-88px -mb-88px flex items-end  mobile:hidden">
                                <Svg src="/icons/loglines2" className="text-lightBrown  mobile:hidden z-0 " />
                            </div>
                            <Svg src="/icons/loglines7" className="text-lightBrown mobile:hidden z-0 -mr-88px" />
                        </div>
                        <div className="grid grid-cols-3 gap-48px mobile:grid-cols-1 col-start-1 row-start-1 z-50">
                            {
                                products.slice(0, showMore ? products.length : 3).filter((p) => p.category == selectedProduct).map((p, i) =>

                                    <ProductCard {...p} />

                                )
                            }
                        </div>

                    </div>


                    <div className=" mt-40px 1920min:mt-100px w-full justify-center text-center">
                        <Button className="h-56px bg-lightGold text-darkGreen border-none" href="#" onClick={(e) => { e.preventDefault(); setShowMore((p) => !p) }}>{showMore ? "SKRÝT" : "OBJEVIT VŠE"}</Button>
                    </div>

                </div>
            </div>
            <div className="mobile:mt-40px" id="contact"></div>
        </div>
    )
}

export default SelectCarousel
