import { InertiaLink, useForm } from '@inertiajs/inertia-react'
import Icon from 'components/Icon'
import React, { useContext, useEffect, useState } from 'react'
import Svg from '../Svg'
import { HeaderProps } from './Header'

function MobileMenu() {

    useEffect(() => {
        document.body.classList.add('overflow-hidden')
        return () => {
            document.body.classList.remove('overflow-hidden')
        }
    }, [])

    let url = window.location.pathname

    return (
        <div className=" bg-darkGreen h-full min-h-noHeader w-full absolute bottom-0 transform duration-300 translate-y-full flex flex-col ">
            <div className="px-16px flex flex-col flex-grow justify-center items-center text-24  relative">
                <div className="relative h-45px flex flex-col-reverse justify-start"> 
                    <HeaderLink href="/produkty" text="Produkty" className={((url=="/produkty")?"text-mediumGold ":"text-lignumText ") + (" dmserif")}/>
                    <Svg src="/icons/underline" className={("absolute ml-5px bottom-0") + ((url=="/produkty")?" ":" hidden ")}/>
                </div>

                <div className="relative h-45px flex flex-col-reverse justify-start">
                <HeaderLink href="/vyroba" text="Zakázková výroba" className={((url=="/vyroba")?"text-mediumGold ":"text-lignumText ") + (" dmserif")}/>
                <Svg src="/icons/underline2" className={("absolute ml-10px bottom-0 ") + ((url=="/vyroba")?" ":" hidden ")}/>
                </div>

                <div className="relative h-45px flex flex-col-reverse justify-start">
                <HeaderLink href="/pribeh" text="Příběh" className={((url=="/pribeh")?"text-mediumGold ":"text-lignumText ") + ("dmserif")}/>
                <Svg src="/icons/underline" className={("absolute ml-2px w-80px bottom-0 ") + ((url=="/pribeh")?" ":" hidden ")}/>
                </div>

                <div className="relative h-45px flex flex-col-reverse justify-start">
                <HeaderLink href="/kontakt" text="Kontakt" className={((url=="/kontakt")?"text-mediumGold ":"text-lignumText ") + (" dmserif")}/>
                <Svg src="/icons/underline" className={("absolute ml-3px bottom-0 ") + ((url=="/kontakt")?" ":" hidden ")}/>
                </div>

                <Icon name="loglines" className="absolute bottom-0 right-0" />
            </div>
            <div className="border-t border-dividerGold border-opacity-16 flex justify-between px-10px 340:px-6px bottom-0  text-lignumText">
                <a target="_blank" href="https://www.instagram.com/lignumnostri/">
                    <div className="uppercase flex text-16 items-center border p-10px border-dividerGold border-opacity-16 ml-10px mt-15px mb-15px cursor-pointer h-55px">
                        <Icon name="ig" className="h-34px 340:h-28px 340:mr-6px mr-10px py-3px"/>
                        <div className="340:text-13">instagram</div>
                    </div>
                </a>
                <a target="_blank" href="https://www.facebook.com/lignumnostri/">
                    <div className="uppercase flex text-16 items-center border p-10px 340:px-6px border-dividerGold border-opacity-16 mr-10px mt-15px mb-15px cursor-pointer h-full max-h-55px">
                        <Icon name="fb-white" className=" h-30px mr-16px 340:mr-4px flex mt-3px 340:ml-0 ml-3px"/>   
                        <div className="340:text-13">facebook</div>
                    </div>
                </a>
            </div>
        </div>

    );
}


function HeaderMobile(props: HeaderProps) {
    const { } = props

    const [open, setOpen] = useState(false)

    const form = useForm({ lang: 'cs' });
    const { data } = form;

    let url = window.location.pathname


    return (
        <>
            <header className={("sticky flex flex-col max-h-screen top-0 z-99999 ") + ((url=="/vyroba")?(open?"bg-darkGreen":"bg-darkBrown"):"bg-darkGreen")}>
                <div className="px-24px flex items-center justify-between py-16px text-lignumText">
                    <InertiaLink href="/">
                        <div>
                            <Icon name="logo" />
                        </div>
                    </InertiaLink>
                    <div onClick={()=>{setOpen((p)=>!p)}} className=" text-dividerGold">
                        <Icon className="z-9999 text-dividerGold " name={(open?"cross":"burger")} />
                    </div>
                </div>
                {
                    open &&
                    <MobileMenu />
                }
            </header>

        </>
    )
}

export default HeaderMobile

interface HLProps {
    href: string
    text: string | any
    active?: boolean
    className?: string
}

function HeaderLink({ href, text, className, active = false }: HLProps) {
    return <InertiaLink href={href} className={("mr-24px ml-8px mb-8px mt-32px ") + (className)}>{text}</InertiaLink>
}

