import React, { FormHTMLAttributes } from 'react'
import { InertiaFormProps } from '@inertiajs/inertia-react'

interface Props extends FormHTMLAttributes<HTMLFormElement> {
    form: InertiaFormProps<any>
}

export const FormContext = React.createContext<InertiaFormProps<Record<string, any>>>({} as InertiaFormProps);

export default function Form(props: Props) {
    const {children, form, ...rest} = props

    return ( 
        <form {...rest}>
            <FormContext.Provider value={form}>
                {children}
            </FormContext.Provider>
        </form>
    )
}


