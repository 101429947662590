import Button from 'app/components/Buttons/Button'
import Icon from 'app/components/Icon'
import { ScreenContext } from 'app/components/ScreenContext'
import Svg from 'app/components/Svg'
import React, { useContext, useState } from 'react'
import { InertiaLink } from '@inertiajs/inertia-react'
import useLazyLoad from 'app/components/hooks/useLazyLoad'
import Img, { ResponsiveImage } from 'app/components/Image'
import route from 'ziggy-js'
import Radio from './Forms/Radio'
import Form from './Forms/Form'
import usePageProps from 'app/components/hooks/usePageProps'
import { Category } from 'app/pages'


interface TileProps{
    name:string
    icon:string
    id:number|null
    active?: boolean
}

function Tile(props: TileProps){
    const{name, icon, id, active = false} = props
    return(
        <label className={(" font-semibold relative p-12px border -ml-1px border-lightGold hover:bg-lightGold transition duration-300 cursor-pointer flex items-center ") + (active?" border bg-lightGold ":"industryTile mobile:border mobile:border-none ")}>
            <div className="flex items-center">
            <Icon className="w-24px h-24px mr-16px" name={icon}/>
            <div className="whitespace-nowrap">{name}</div>
            </div>
            <Radio name="category" value={id??""} className="absolute opacity-0 w-6px" />
        </label>
    )
}

interface ProductProps{
    name: string,
    price: string,
    category: string,
    icon: string,
    images: Array< ResponsiveImage | string>,
    url_key: string,
}

function ProductCard(props:ProductProps){
    const{name, price, category, icon, images, url_key} = props 
    return ( 
        <div className="h-full w-full mobile:max-w-mobileFull mobile:min-h-378px min-h-478px 1441min:min-h-600px notMobile:mx-20px mobile:mb-0 mb-16px">
            <div className="grid grid-cols-1 grid-rows-1 h-full mr-16px rounded-sm ">
                <div className="col-start-1 row-start-1 ml-20px mt-20px min-h-343px w-full h-90% border border-mediumGold rounded-sm">
                    <div className="relative w-full h-full">
                        <div className="flex flex-col w-full absolute bottom-0 mb-60px">
                            <div className="flex justify-between w-full">
                                <div className="font-semibold text-16 ml-16px w-70% 340:text-12 mobile:text-10 overflow-visible">{name}</div>
                                <Svg src={("/icons/") + (icon)} className=" h-24px pt-5px mx-30px"/>
                            </div>
                            <div className="text-14 mx-16px mobile:text-10">od {price} Kč</div>
                        </div>
                        <InertiaLink href={route("products.show", { product: url_key })} className="absolute bottom-0 left-0 h-56px w-full border-t border-mediumGold hover:bg-lightGold transition duration-300">
                            <div className="w-90% h-full flex justify-between items-center">
                                <div className="ml-16px">Objevit</div>
                                <Icon name="dropright" className=""/>
                            </div>
                        </InertiaLink>
                    </div>
                </div>
                <div className=" col-start-1 row-start-1 rounded-sm grid grid-rows-1 grid-cols-1">
                    <Img src={images[0]} alt="" className=" col-start-1 row-start-1 rounded-sm w-full h-75% 370:h-60% 460:h-65% 850px:h-60% 1149px:h-67% 1920min:h-75% 2560min:h-81%       object-cover" />
                    <InertiaLink className="cursor-pointer z-50 col-start-1 row-start-1 w-full        h-75% 370:h-60% 460:h-65% 850px:h-60% 1149px:h-67% 1920min:h-75% 2560min:h-81%"      href={route("products.show", { product: url_key })}></InertiaLink>
                </div>
            </div>
        </div>
    )
}

interface Props {}


function SelectCarousel(props: Props) {
    const {} = props

    const {categories, active} = usePageProps<{categories: Array<Category>, active: number}>()

    let [selectedProduct, setSelectProduct] = useState("favourite")
    let {isMobile} = useContext(ScreenContext)
    const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)

    let [showMore, setShowMore] = useState(false)
    const [products, button, meta, form, setProducts] = useLazyLoad<ProductProps>('products');
    const { data } = form;

    return (
        <div id="industries" className="pb-60px mobile:pt-24px pt-80px">
            <div className="text-24 dmserif pb-16px notMobile:mx-160px mobile:mx-16px" >Naše výrobky</div>
            <Form form={form} className="rounded-r-sm rounded-l-sm">
                <div className="flex mx-160px mobile:ml-0 mobile:pl-16px mobile:w-full justify-start overflow-x-auto mb-24px border-lightGold 1920min:border-l transition duration-300">
                    <Tile name="Nejoblíbenější" icon="favourite" id={null} active={!active}  />
                    {
                        categories.map((i,k)=>
                            <Tile key={k} name={i.name} icon={i.icon} id={i.id} active={i.id == active} />
                        )
                    }
                    
                </div>

                <div className="850px:mx-80px 1149px:mx-160px mx-16px 850px:max-w-mobileFull">
                    <div className=" w-full h-full flex flex-col justify-items-center 850px:grid grid-cols-3 gap-40px">
                        {
                            products.map((p,i)=>
                            <ProductCard {...p} />
                            )
                        }
                    </div>
                    
                    <div className="flex mx-auto items-center justify-center pt-24px">
                    {
                        <div className="">Zobrazuji {meta.to} z {meta.total}</div>
                    }
                    </div>
                </div>

                <div className=" mt-24px w-full mobile:ml-16px mobile:w-mobileFull flex justify-center">
                    <Button {...button} className={("h-56px mobile:w-full bg-lightGold text-darkGreen border-lightGold ") + (meta.to==meta.total?"hidden":"") } href="">
                        NAČÍST DALŠÍ
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default SelectCarousel
