import { InertiaLink } from '@inertiajs/inertia-react'
import React, { useContext, useState } from 'react'
import Icon from 'components/Icon'
import { ScreenContext } from '../ScreenContext'

interface Props { }

function Footer(props: Props) {
    const { } = props
    let url = window.location.pathname
    let { isMobile } = useContext(ScreenContext)

    return (
        <footer className=" bg-darkBrown z-99999 min-w-full notMobile:pt-85px mobile:pt-24px">
            <div className="flex flex-col notMobile:mx-160px mobile:mx-16px mobile:grid mobile:justify-items-start">
                <div className="flex notMobile:justify-center">
                    <a href="/" >
                        <Icon name="logo" className="text-mediumGold" />
                    </a>
                </div>

                <div className="text-lignumText items-center flex notMobile:justify-center pt-16px text-16 ">
                    <div className="notMobile:w-375px notMobile:text-center text-white">
                        Jsme Lignum Nostri - dva lidé, které spojuje láska ke dřevu a poctivému řemeslu.
                    </div>
                </div>

                <div className="w-full border-t border-dashed border-dividerGold mt-32px border-opacity-16 notMobile:hidden" />

                <div className="flex mobile:grid mobile:grid-rows-2 mobile:grid-cols-2 text-white notMobile:justify-evenly notMobile:p-40px mobile:pt-16px mobile:mb-0 mb-32px text-16">
                    <InertiaLink href="/produkty" className="col-start-1 row-start-1 mobile:pt-12px mobile:pr-12px mobile:pb-12px">
                        <div className="">Produkty</div>
                        <Icon name="produktyunderline" className={(url == "/produkty") ? " " : "hidden "} />
                        <Icon name="produktyunderline" className={(url == "/product") ? " " : "hidden "} />
                    </InertiaLink>
                    <InertiaLink href="/vyroba" className="col-star-2 row-start-1 mobile:p-12px">
                        <div className="">Zakázková výroba</div>
                        <Icon name="vyrobaunderline" className={(url == "/vyroba") ? " 370:hidden " : " hidden "} />
                        <Icon name="produktyunderline" className={(url == "/vyroba") ? " 370min:hidden " : " hidden "} />
                    </InertiaLink>
                    <InertiaLink href="/pribeh" className="col-start-1 row-start-2 mobile:pt-12px mobile:pr-12px mobile:pb-12px">
                        <div className="">Příběh</div>
                        <Icon name="pribehunderline" className={(url == "/pribeh") ? " " : "hidden "} />
                    </InertiaLink>
                    <InertiaLink href="/kontakt" className="col-start-2 row-start-2 mobile:p-12px">
                        <div className="">Kontakt</div>
                        <Icon name="kontaktunderline" className={(url == "/kontakt") ? " " : "hidden "} />
                    </InertiaLink>
                </div>

                <div className="w-full border-t border-dashed border-dividerGold mb-32px border-opacity-16" />

                <div className="flex justify-between notMobile:items-center mb-85px mobile:mb-40px text-16 mobile:flex-col-reverse mobile:w-full mobile:flex mobile:justify-start">
                    <div className="text-white">
                        © 2021 Lignum Nostri – Všechna práva vyhrazena
                    </div>
                    <div className="flex notMobile:items-center">
                        <a target="_blank" href="/assets/gdpr_lignumnostri.pdf" className="hover:underline  text-white  mr-16px mobile:pb-16px mobile:w-full">{isMobile ? "Zásady ochrany osobních údajů" : "Zásady ochrany osobních údajů"}</a>
                        <a href="#top" onClick={(e) => { e.preventDefault(); window.scrollTo({ top: 0, behavior: "smooth" }) }} className="p-10px  items-center flex justify-center">
                            <Icon name="right" className="text-white h-32px mobile:hidden pt-10px" />
                        </a>
                    </div>
                </div>

            </div>
        </footer>
    )
}

export default Footer

interface FLProps {
    href: string
    text: string | any
    className?: string
}

function FooterLink({ href, text, className }: FLProps) {
    return <InertiaLink href={href} className={("ml-24px mobile:ml-0 ") + (className)}>{text}</InertiaLink>
}

