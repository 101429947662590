import Icon from 'components/Icon'
import React from 'react'

interface Props {
    text?: string | any
    icon: string
    className?: string
    iconClass?: string
}

function IconText(props: Props) {
    const {text, icon, className, iconClass} = props

    return (
        <div className={`flex ${className}`}>
            <Icon name={icon} className={`mr-12px ${iconClass}`} />
            {text}
        </div>
    )
}

export default IconText
