import Button from 'components/Buttons/Button'
import IconText from 'fragments/IconText'
import React, { InputHTMLAttributes, useContext } from 'react'
import { FormContext } from './Form'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string | any
    imagePreview?: boolean
    height?: string
}

function ImageInput(props: Props) {
    const { label, name = "file", imagePreview = false, height = "h-160px", ...rest } = props

    const { data, setData, errors, clearErrors } = useContext(FormContext)

    let fileList = data?.[name] as FileList;

    let preview = fileList ? Object.values(fileList)?.[0] : null;

    if (name.includes('[]'))
        throw new Error("Dont include [] in input name for multiple files, just add prop 'multiple | multiple={true}' ");


    return (
        <label className={`cursor-pointer relative block `}>
        
            <div>{label}</div>
            <input
                className="opacity-0 w-0 h-0 absolute"
                type="file"
                {...rest}
                name={`${name}[]`}
                onChange={e => { setData(name, e.target.files); clearErrors(name) }}
            />
            <div className={`flex items-center flex-col bg-EBE9E6 rounded justify-center ${height} relative z-10 overflow-hidden`}>
                {
                    (imagePreview && preview && preview.type.startsWith("image")) &&
                    <img className="absolute top-0 left-0 h-full w-full object-cover object-center z-0" src={ URL.createObjectURL(preview)} alt="Preview" />
                }
                <Button className="pointer-events-none mob:w-full text-left justify-start z-10" href="/noJS" onClick={e => { e.preventDefault(); }}><IconText icon="upload" text={(fileList?.length > 0) ? "Změnit soubor" : "Nahrát soubor"} /></Button>
                <div className="mt-12px text-12 mob:ml-0 mob:mt-8px z-10 bg-EBE9E6 rounded px-6px bg-opacity-80">
                    {
                        (fileList?.length > 0)
                            ? (
                                Object.values(fileList)?.map((f: any) =>
                                    `${f?.name} (${Math.floor(f?.size / 1000 / 10) / 100} Mb)`
                                ).join(', ')

                            )

                            : "Maximální velikost souboru je 5Mb"
                    }
                </div>
            </div>
            <div className={`h-12px text-12 text-C86B28 ${errors[name] ? "" : "opacity-0"}`}>{errors?.[name]}</div>
        </label>
    )
}

export default ImageInput
