import GaleryOverlay from 'app/fragments/GaleryOverlay';
import React, { createContext, useContext, useEffect, useState } from 'react'


interface Props {}

export enum MODALS {
    GALERY
}

export const ModalsContext = createContext({modal : null as {modal: MODALS, data?:any} | null, open : (_modal: MODALS, goBack = false, data = {} as any) => {}, close : () => {} });


export function ModalsProvider(props) {

    const [modal, setModal] = useState<{modal: MODALS, data?: any} | null> (null)
    const [stack, setStack] = useState<Array<{modal: MODALS, goBack: boolean, data?: any}>>([]);

    const open = (_modal: MODALS, goBack = false, data = {}) => {
        setModal({modal: _modal, data: data});
        setStack(s => [...s,{modal: _modal, goBack, data}]);
    }

    const back = () => {      
        setStack((s) => {
            let ss = [...s];
            let prev = ss?.pop() ?? null;
            ss = prev?.goBack ? ss : [];

            let penu = ss?.pop() ?? null;

            setModal(penu);

            return [...ss, ...( penu ? [penu] : []) ];
        }); 
    }

    const close = () => {
       back();
    }

    
    return (
        <ModalsContext.Provider value={{modal, open, close }} >
            {props.children}
        </ModalsContext.Provider>
    )
}

function Modals(props: Props) {
    const {} = props

    const {modal} = useContext(ModalsContext)

    return (
        <>
            { (modal?.modal == MODALS.GALERY) && <GaleryOverlay {...modal.data} /> }
        </>
    )
}

export default Modals
