//import { ApiMarkers } from 'api/Map/mapRequests';
import React, { useEffect, useState } from 'react';
//import AspectRationContainer from '../AspectRationContainer';
​
interface Props {
  className?: string;
  center?: { latitude: number; longitude: number };
}
​
var map: any;
​
function Map(props: Props) {
  const { className, center } = props;
  let [active, setActive] = useState(false);
  let [markers, setMarkers] = useState([]);
​
  useEffect(() => {
    // console.log(center)
    if (map && center) {
      map.flyTo({
        center: [center.latitude, center.longitude],
        zoom: 13,
      });
    }
  }, [center]);
​
  let scriptLoaded = () => {
    // @ts-ignore
    let mapboxgl = window.mapboxgl;
​
    mapboxgl.accessToken =
      'pk.eyJ1IjoiZ2FsYXRleCIsImEiOiJjazhxMzk3dHowMDBtM2VtdXhxbTF6b2Z2In0.W439wQtZmDTjOcUIhIUKdQ';
    map = new mapboxgl.Map({
      container: 'map',
      center: [17.462519492046642, 49.526109005393735],
      zoom: 14,
      style: 'mapbox://styles/mapbox/dark-v10'
    });
    var geojson = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [17.462519492046642, 49.526109005393735],
          },
          properties: {
            title: 'Lignum Nostri',
            description: 'Lazníky 37, 751 25',
          },
        },
      ],
    };
​
    // // add markers to map
    // geojson.features.forEach(function(marker) {
​
    // create a HTML element for each feature
    var el = document.createElement('div');
    el.className = 'marker';
    const coordinates = geojson.features[0].geometry.coordinates;
    const title = geojson.features[0].properties.title;
    const description = geojson.features[0].properties.description;
​
    // make a marker for each feature and add to the map
    new mapboxgl.Marker(el)
      .setLngLat([coordinates[0], coordinates[1]])
      .setPopup(
        new mapboxgl.Popup({ offset: 25 }) // add popups
          .setHTML('<h3>' + title + '</h3><p>' + description + '</p>')
      )
      .addTo(map);
    // });
​
    //     ApiMarkers()
    //       .then((res) => {
    //         // console.log(res)
    //         let { data } = res;
    //         // console.log(markers);
    //         for (const marker of data) {
    //           // create a HTML element for each feature
​
    //           var el = document.createElement('div');
    //           el.className = 'marker';
    //           // console.log(marker.longitude, marker.latitude)
    //           // make a marker for each feature and add to the map
    //           new mapboxgl.Marker(el)
    //             .setLngLat([marker.longitude, marker.latitude])
    //             .setPopup(
    //               new mapboxgl.Popup({ offset: 25 }) // add popups
    //                 .setHTML(
    //                   '<h3>' +
    //                     marker.name +
    //                     '</h3>' +
    //                     '<p>' +
    //                     marker.address +
    //                     '</p>'
    //                 )
    //             ) //'<p>' + marker.description + '</p>'
    //             .addTo(map);
    //         }
    //       })
    //       .catch((err) => console.log(err));
  };
​
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.js';
    script.async = true;
    script.onload = () => scriptLoaded();
​
    document.body.appendChild(script);
  }, []);
​
  useEffect(() => {
    // @ts-ignore
    let mapboxgl = window.mapboxgl;
  }, [markers]);
​
  return (
    <div className={className + ' relative'}>
      <div id="map" className="w-full h-full"></div>
      {!active && (
        <div
          className="w-full h-full absolute left-0 top-0"
          onMouseEnter={() => setActive(true)}
          onMouseLeave={() => setActive(false)}
        ></div>
      )}
    </div>
  );
}
​
export default Map;