import { usePage } from '@inertiajs/inertia-react'
import usePageProps from 'app/components/hooks/usePageProps'
import Icon from 'app/components/Icon'
import SearchBox from 'app/fragments/SearchBox'
import React from 'react'
import SearchBar from '../SearchBar'

interface Props {}

function DesktopHeader(props: Props) {
    const {} = props

    const {user} = usePageProps<{user:any}>()

    return (
        <div className="bg-bg h-85px items-center flex flex-row px-16px w-full border-b border-C4C4C4">
            <div className="flex justify-between w-full">
                <Icon name="spin-logo" />
                <div className="w-450px">
                    <SearchBar />
                </div>
                <div className="flex items-center">
                    <div className="mr-16px">
                        <div className="font-semibold text-16">{user.name}</div>
                        <div className="font-normal text-12">{user.email}</div>
                    </div>
                    <Icon name="dropdown-arrow" />
                </div>
            </div>
        </div>
    )
}

export default DesktopHeader
