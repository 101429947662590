import Layout from "components/Layouts/Layout";
import React, { useContext, useState } from "react";
import { ScreenContext } from "components/ScreenContext";
import { InertiaLink, useForm } from "@inertiajs/inertia-react";
import Modals, { MODALS, ModalsContext } from "app/components/Layouts/Modals";
import KontaktLanding from "app/components/KontaktLanding";
import ZakazkovaVyroba from "app/components/ZakazkovaVyroba";
import Socials from "app/components/Socials";


interface Props{
  className?: string,
}


export default function Home(props: Props) {
  const { isMobile } = useContext(ScreenContext);
  const {open} = useContext(ModalsContext)
  const form = useForm({});
  const {data} = form;
  return (
    <Layout>
      <div className="bg-bg w-full h-full">
        <KontaktLanding />
        <ZakazkovaVyroba />
        <Socials />
      </div>
    </Layout>
  );
}