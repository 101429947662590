import React, { HTMLProps } from 'react'
import Header from './header/Header'
import SideMenu from './SideMenu'

interface Props extends HTMLProps<HTMLDivElement> {
    onLogin?:boolean
}

function Layout(props: Props) {
    const {children, onLogin=false} = props

    return (
        <div>
            <Header />
            <div className="flex">
                <SideMenu />
                <div className="bg-bg w-full pl-23px pr-20px pt-26px">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout
