import ImgJpg from './ImgJpg';

export default function ProductsLanding(){

  return(
    <>
      <div className="notMobile:py-100px relative mobile:py-16px">
        <div className="flex justify-between mobile:flex-col-reverse items-center mx-16px notMobile:mx-160px">
          <div className="flex flex-col  max-w-lg">
            <div className="text-darkBrown text-h32 dmserif notMobile:pb-32px w-90%">
            Jsme Lignum Nostri - dva lidé, které&nbsp;spojuje láska ke&nbsp;dřevu a&nbsp;poctivému řemeslu.
            </div>
            <div className="mobile:mt-32px">Potřebujete poradit s&nbsp;výběrem?  Rádi s&nbsp;vámi vše&nbsp;probereme a&nbsp;najdeme pro&nbsp;vás&nbsp;to&nbsp;pravé. Můžete nám&nbsp;napsat či&nbsp;zavolat.</div>
            <div className="w-divider border-t border-dividerGold my-32px mobile:hidden"/>
          </div>
          <ImgJpg src="team" className=" max-w-550px 2560min:max-w-5xl mobile:max-w-327px mobile:mr-16px"/>
        </div>
      </div>
    </>
  )
};