import Layout from "components/Layouts/Layout";
import React, { useContext, useState } from "react";
import { ScreenContext } from "components/ScreenContext";
import { InertiaLink, useForm } from "@inertiajs/inertia-react";
import Modals, { MODALS, ModalsContext } from "app/components/Layouts/Modals";
import Icon from "app/components/Icon";
import Button from "app/components/Buttons/Button";
import ImgJpg from "app/components/ImgJpg";

export interface Category {
  id: number,
  name: string,
  icon: string
}

interface Props {
  className?: string,
  categories: Array<Category>
}




export default function Home(props: Props) {
  const { isMobile } = useContext(ScreenContext);
  const { open } = useContext(ModalsContext)
  const form = useForm({});
  const { data } = form;
  return (
    <>
      <div className="relative w-full h-screen mobile:h-610px flex bg-bg items-center justify-center">

        <div className="absolute w-full h-full mobile:w-mobileFull">
          <div className="relative mobile:">
            <div className="absolute w-full items-center flex justify-center 390:justify-start">
              <InertiaLink href="/" className=" h-full mt-16px">
                <Icon name="logo" className="h-40px" />
              </InertiaLink>
            </div>

            <div className="absolute right-0 top-0">
              <InertiaLink href="/" className="flex font-karla items-center notMobile:m-24px mobile:mt-24px">
                <div className="text-16px mx-12px">Zavřít</div>
                <Icon name="cross" />
              </InertiaLink>
            </div>
            <Icon name="loglines6" className="absolute right-0 mobile:-right-16px mt-67px opacity-16" />
            <Icon name="loglines3" className="absolute mt-250px left-0 mobile:-left-16px  opacity-16" />
          </div>
        </div>

        <div className=" relative mobile:mt-100px ">
          <div className="grid grid-cols-2 mobile:grid-cols-1 mobile:grid-rows-2 gap-32px max-w-mobileFull items-center mobile:flex-col-reverse mx-16px notMobile:mx-160px">
            <div className=" max-w-lg notMobile:mr-120px">
              <div className="text-darkBrown text-h32 dmserif ">
              Srdečně Vám děkujeme!
              </div>
              <div className=" mt-24px mb-32px notMobile:mr-32px notMobile:w-95%">
              Nejpozději následující pracovní den se&nbsp;Vám&nbsp;ozveme.
              </div>
              <Button href="/" className="uppercase inline bg-darkGreen border-darkGreen hover:text-white hover:bg-mediumGold hover:border-mediumGold">
                zpět na úvodní stránku
              </Button>
              <div className="w-full -mt-32px border-t border-dividerGold mb-32px mobile:hidden" />
            </div>
            <ImgJpg src="team" className=" max-w-550px 2560min:max-w-5xl mobile:max-w-327px mobile:mr-16px " />
          </div>
        </div>

      </div>
      <div className="h-16px bg-bg w-full notMobile:hidden"></div>
    </>
  );
}