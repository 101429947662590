import Img from 'app/components/Image';
import Icon from 'components/Icon';
import React, { useEffect, useRef, useState } from 'react'


function Video(props) {
    const {className, ...rest} = props

    const [isVideoLoaded, setIsVideoLoaded] = useState(false);

    const videoRef = useRef();

    const onLoadedData = () => {
        setIsVideoLoaded(true);
    };

    useEffect(()=> {

        if(!props.shouldplay)
            setIsVideoLoaded(false);
        
    },[props.shouldplay])

    return (
        <div className="grid grid-cols-1 grid-rows-1 w-full h-full">
            <video ref={videoRef} onPlay={onLoadedData} {...rest} className={className + " col-start-1 row-start-1"}  onLoadedData={onLoadedData} style={{ opacity: isVideoLoaded ? 1 : 0 }}>
                <source src={props.src} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
            <Img
                src={props.thumbnail}
                className={"col-start-1 row-start-1 image-full "+ (className?.includes('rounded') ? "rounded-lg" : "")}
                alt="thumbnail"
                style={{ opacity: isVideoLoaded ? 0 : 1, pointerEvents: "none" }}
            />
        </div>
        
    )
}

export default Video

