import React, { useState, useContext } from 'react'
import { ScreenContext } from 'components/ScreenContext';
import { MODALS, ModalsContext } from 'app/components/Layouts/Modals';
import Img from './Image';

export default function Galery({ className = "", name, images: imgs = [] as any, under = 3 }) {

  let [index, setIndex] = useState(0);
  let [shouldPlay, setShouldPlay] = useState(true);

  const { open } = useContext(ModalsContext)
  let { isMobile } = useContext(ScreenContext);

  console.log(imgs[0])

  let image = imgs?.[index];


  let selectPhoto = (e, index) => {
    e.preventDefault();
    setIndex(index);
  }

  return (
    <>
      <div className=" w-full relative notMobile:ml-100px mb-10px 1920min:max-w-3xl 2560min:max-w-4xl">
        <div className="mobile:max-w-mobileFull mobile:mx-16px w-full notMobile:sticky top-112px">
          <div className="grid grid-cols-3 grid-rows-4 mobile:grid-rows-3 gap-x-20px w-full bg-bg border mobile:border-none border-lightGold pt-16px pr-16px pl-16px notMobile:pb-16px">
            <div className=" row-start-1 col-start-1 col-span-3 row-span-3 -ml-32px -mt-40px h-full">
              <Img src={imgs[0]?.url} alt="" className="mobile:hidden max-h-511px 1920min:max-h-610px 2560min:max-h-720px h-full w-full object-cover object-center cursor-pointer" onClick={() => open(MODALS.GALERY, false, { imgs: imgs, index: 0, name })} />
              <div className="relative mb-40px notMobile:hidden ml-16px">
                <div className="absolute w-full h-full left-0 top-0 ml-16px mt-16px z-0 max-w-lg border border-mediumGold" />
                <img src={imgs[0]?.url} className="z-10 relative object-cover max-h-266px max-w-lg h-full w-full rounded-sm cursor-pointer" onClick={() => open(MODALS.GALERY, false, { imgs: imgs, index: 0, name })} />
              </div>
            </div>
            {
              imgs[1]?.url &&
              <div className=" w-full h-full mobile:hidden max-h-150px 1920min:max-h-200px 2560min:max-h-232px">
                <img src={imgs[1]?.url} alt="" className="h-full w-full object-cover object-center max-h-150px 1920min:max-h-200px 2560min:max-h-232px cursor-pointer" onClick={() => open(MODALS.GALERY, false, { imgs: imgs, index: 1, name })} />
              </div>
            }
            {
              imgs[2]?.url &&
              <div className=" w-full h-full mobile:hidden max-h-150px 1920min:max-h-200px 2560min:max-h-232px">
                <img src={imgs[2]?.url} alt="" className="h-full w-full object-cover object-center max-h-150px 1920min:max-h-200px 2560min:max-h-232px cursor-pointer" onClick={() => open(MODALS.GALERY, false, { imgs: imgs, index: 2, name })} />
              </div>
            }
            {
              imgs[3]?.url &&
              <div className="relative w-full h-full cursor-pointer mobile:hidden max-h-150px 1920min:max-h-200px 2560min:max-h-232px" onClick={() => open(MODALS.GALERY, false, { imgs: imgs, index: 3, name })}>
                <img src={imgs[3]?.url} alt="" className="absolute h-full w-full object-cover object-center max-h-150px 1920min:max-h-200px 2560min:max-h-232px" />
                <div className={("max-h-150px 1920min:max-h-200px 2560min:max-h-232px absolute w-full h-full flex items-center justify-center bg-darkBrown bg-opacity-40") + (imgs.length == 4 ? " hidden" : "")}>
                  <div className="w-full h-full flex items-center justify-center text-white font-extrabold ">{imgs.length == 4 ? "" : ("+") + (imgs.length - 4)}</div>
                </div>
              </div>
            }
          </div>
        </div>
        <div className="notMobile:hidden flex w-full h-100px overflow-x-scroll mb-32px" onClick={() => open(MODALS.GALERY, false, { imgs: imgs, index: 2, name })}>
          {
            imgs.slice(1, imgs.lenght).map((i) =>
              <img className="h-full w-full object-contain object-center mx-8px" src={i.url} alt="" />
            )
          }
        </div>
      </div>
    </>
  )
}