import Icon from 'components/Icon'
import React, { InputHTMLAttributes, useContext, useEffect, useRef, useState } from 'react'
import { FormContext } from './Form'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    options: Array<{ text: string | any, value: string }>
    placeholder?: string
    onChange?: any
    label?: string | any
    name?: string
    labelClassName?:string
    containerClassName?:string
}

function Select(props: Props) {
    const { options, placeholder,labelClassName, className, containerClassName, onChange = (v) => { }, label, name = "select", ...rest } = props

    // const [selected, setselected] = useState<{ text: string, value: string } | null>(null)
    const [open, setopen] = useState(false)

    // const [open, setopen] = useState(false)
    const ref = useRef<HTMLDivElement>(null);
    const height = useRef("0px");

    const collapseSection = () => {
        let element = ref.current;
        if (element) {
            // get the height of the element's inner content, regardless of its actual size
            var sectionHeight = element.scrollHeight;

            // temporarily disable all css transitions
            var elementTransition = element.style.transition;
            element.style.transition = '';

            // on the next frame (as soon as the previous style change has taken effect),
            // explicitly set the element's height to its current pixel height, so we 
            // aren't transitioning out of 'auto'
            requestAnimationFrame(function () {
                if (element) {
                    height.current = sectionHeight + 'px';
                    element.style.height = height.current;
                    element.style.transition = elementTransition;
                }

                // on the next frame (as soon as the previous style change has taken effect),
                // have the element transition to height: 0
                requestAnimationFrame(function () {
                    if (element) {
                        height.current = 0 + 'px';
                        element.style.height = height.current;
                    }
                });
            });

            setopen(false);
        }
    };

    const expandSection = () => {
        let element = ref.current;
        if (element) {
            var sectionHeight = element.scrollHeight;
            height.current = sectionHeight + 'px';
            element.style.height = height.current;
            setopen(true);
        }
    };

    const select = (o: { text: string, value: string } | null) => {
        setData(name, o?.value);
        onChange(o);
        collapseSection();
    }

    const closeOnClick = () => {
        collapseSection()
    }

    const checkOnClick = (event) => {
        event.stopPropagation();
    }
    useEffect(() => {
        window.addEventListener('click', closeOnClick)
        return () => {
            window.removeEventListener('click', closeOnClick)
        }
    }, [])

    useEffect(() => {
        collapseSection()
    }, [])

    const { data, setData, errors, clearErrors } = useContext(FormContext)

    let selected = options.find(o => o.value == data?.[name]);

    return (
        <label className={("relative ") + containerClassName} onClick={checkOnClick}>
            <div className={("text-14 " + labelClassName)}>{label}</div>
            <div className={`flex items-center h-54px  rounded-default cursor-pointer relative`}>
                <div className="flex justify-between flex-grow items-center px-16px rounded-sm  bg-darkGreen pr-0 border border-EBE9E6" onClick={(!open ? expandSection : collapseSection)}>
                    <div className={` leading-4 ${selected?.text ? "" : "text-C4C4C4"}`}>{selected?.text ?? placeholder}</div>
                    <div className={`h-54px w-54px flex items-center justify-center transition-colors duration-300 ${open ? "border-transparent" : "border-transparent"}`}>
                        <Icon className={`transform transition-transform duration-300 w-12px h-12px ${open && 'rotate-180'}`} name="dropdown-arrow" />
                    </div>
                </div>
                <div ref={ref} className="overflow-hidden collapsable absolute bottom-0 left-0 transform w-full translate-y-full mb-8px z-10   ">
                    <div className="bg-darkGreen absolute top-0 left-0 pt-16px w-full shadow-md rounded-b-sm border border-EBE9E6 border-t-0 ">
                        {/* <div className="border-t px-16px hover:bg-F8F2EB border-EBE9E6 py-8px cursor-pointer" onClick={() => select(null)}>{placeholder}</div> */}
                        {
                            options.map((o, i) =>
                                <div key={`option-${i}`} className="border-t leading-4 px-16px hover:bg-F8F2EB border-EBE9E6 py-8px cursor-pointer" onClick={() => select(o)}>{o.text}</div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={`h-12px text-12 text-redred text-opacity-70 w-full flex justify-end ${errors[name] ? "" : "opacity-0"}`}>{errors?.[name]}</div>
            <input
                className={`opacity-0 w-0 h-0 absolute ${className}`}
                type="text"
                {...rest}
                name={name}
                value={data[name] ?? ""}
                onChange={e => {setData(name, e.target.value); clearErrors(name)}}
            />
        </label>
    )
}

export default Select
