import Icon from 'components/Icon'
import React, { InputHTMLAttributes, useContext, useEffect, useRef, useState } from 'react'
import { ErrorGroupContext } from './ErrorGroup';
import { FormContext } from './Form'

interface Props extends InputHTMLAttributes<HTMLSelectElement> {
    options: Array<{ text: string | any, value: string | number | any }>
    placeholder?: string
    onChange?: any
    label?: string | any
    name?: string
    labelClassName?: string
    showPlaceholder?: boolean
    clearSelection?: boolean
    nameForHiddenSelect?: string
    leftBorder?: boolean
    clickId?: string
    color?: string
}

function Select(props: Props) {
    const { options, clickId, placeholder, color, leftBorder = false, showPlaceholder = false, labelClassName, className, onChange = (v) => { }, label, name = "select", clearSelection = false, nameForHiddenSelect = name, ...rest } = props

    const [open, setopen] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const height = useRef("0px");

    const { data, setData, errors, clearErrors } = useContext(FormContext)

    const { seterrors } = useContext(ErrorGroupContext);
    useEffect(() => {
        seterrors(e => ({ ...e, [name]: errors[name] }))
    }, [errors[name]])

    const collapseSection = () => {
        let element = ref.current;
        if (element) {

            var sectionHeight = element.scrollHeight;
            var elementTransition = element.style.transition;
            element.style.transition = '';

            requestAnimationFrame(function () {
                if (element) {
                    height.current = sectionHeight + 'px';
                    element.style.height = height.current;
                    element.style.transition = elementTransition;
                }
                requestAnimationFrame(function () {
                    if (element) {
                        height.current = 0 + 'px';
                        element.style.height = height.current;
                    }
                });
            });

            setopen(false);
        }
    };

    const expandSection = () => {
        let element = ref.current;
        if (element) {
            var sectionHeight = element.scrollHeight;
            height.current = sectionHeight + 'px';
            element.style.height = height.current;
            setopen(true);
        }
    };

    const toggle = () => {
        if (open)
            collapseSection()
        else
            expandSection()
    }

    const select = (o: { text: string, value: string | number } | null) => {
        setData(name, o?.value);
        clearErrors(name)
        onChange(o);
        collapseSection();
    }

    // useEffect(() => {
    //     collapseSection()
    // }, [])



    let selected = options.find(o => o.value == data?.[name]);

    const closeOnClick = () => {

        collapseSection()
    }

    const checkOnClick = (event) => {
        event.stopPropagation();
    }

    useEffect(() => {

        try {
            window?.addEventListener('click', closeOnClick)
        } catch (error) { }

        return () => {
            try {
                window?.removeEventListener('click', closeOnClick)
            } catch (error) { }
        }
    }, [])

    return (
        <div className="relative" onClick={checkOnClick}>
            <div className={(" " + labelClassName)}>{label}</div>
            <div className={`flex items-center h-55px  rounded cursor-pointer relative`}>
                <div className={(`flex justify-between flex-grow items-center px-16px rounded  ${color?("bg-" + color):"bg-brown"} pr-0 border ${errors[name] ? "border-C86B28" : "border-C4C4C4"} `)} onClick={toggle}  id={clickId}>
                    <div className={` leading-4 ${selected?.text ? "" : "text-C4C4C4"}`}>{selected?.text ?? placeholder}</div>
                    {
                        (clearSelection && data[name]) ?
                            <div onClick={e => { e.stopPropagation(); select(null); }} className={`h-55px w-55px flex items-center justify-center border-l ${leftBorder ? 'border-C4C4C4' : 'border-transparent'} hover:text-red`}>
                                <Icon className={` `} name="cross" />
                            </div>

                            :
                            <div className={`h-55px w-55px flex items-center justify-center transition-colors duration-300 `}>
                                <Icon className={`transform transition-transform duration-300 ${open && 'rotate-180'}`} name="dropdown-arrow" />
                            </div>
                    }
                </div>
                <div ref={ref} className="overflow-x-hidden overflow-y-hidden collapsable absolute bottom-0 left-0 transform w-full selectmask translate-y-full mb-8px z-50" style={{ height: height.current }}>
                    <div className="bg-white absolute top-0 left-0  w-full shadow-md rounded-b-default border border-C4C4C4 border-t-0  overflow-x-hidden max-h-280px tagscrollbar z-50">
                        {showPlaceholder && <div className="border-t px-16px hover:bg-F8F2EB border-C4C4C4 py-8px cursor-pointer" onClick={() => select(null)}>{placeholder}</div>}
                        {
                            options.map((o, i) =>
                                <div key={`option-${i}`} className="border-t leading-4 px-16px hover:bg-F8F2EB border-C4C4C4 py-8px cursor-pointer" onClick={() => select(o)}>{o.text}</div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div className={`h-12px mt-4px text-12 text-C86B28 ${errors[name] ? "" : "opacity-0"}`}>{errors?.[name]}</div>
            <select
                className={`opacity-0 w-0 h-0 absolute ${className}`}
                {...rest}
                name={nameForHiddenSelect}
                value={data[name] ?? ""}
                onChange={e => { setData(name, e.target.value); clearErrors(name) }}
                onMouseDown={(e) => {
                    try {
                        e.preventDefault()
                        e.currentTarget.blur();
                        window?.focus();
                    } catch (error) { }
                }}
                onFocus={(e) => {
                    try {
                        e.preventDefault()
                        e.currentTarget.blur();
                        window?.focus();
                    } catch (error) { }
                }}
            >
                {
                    options.map((o, i) =>
                        <option key={`option-${i}`} value={o.value}>{o.text}</option>
                    )
                }
            </select>
        </div>
    )
}

export default Select
