import WhiteDropdowns from 'app/fragments/WhiteDropdowns';
import React, { useState, useEffect, useRef } from 'react';
import Button from './Buttons/Button';
import Galery from './Galery';
import Svg from './Svg';


interface ProductDetailsProps {
  product: any
}

export default function ProductDetailLanding(props: ProductDetailsProps) {

  let { product } = props
  let data = product.data
  console.log(data)
  return (
    <>
      <div className="relative">
        <div className="flex mobile:flex-col-reverse notMobile:mx-160px justify-between pt-40px">
          <div className=" h-full text-darkGreen text-16  mobile:max-w-mobileFull w-full max-w-2xl mobile:mx-16px">

            <div className="flex flex-col notMobile:mt-48px">
              <div className="text-24 dmserif pb-14px ">
                {data.name}
              </div>

              <div className=" pb-24px">
                {data.description}
              </div>

              <div className="flex flex-col pb-16px notMobile:pb-63px">
                <a href="#spec" onClick={(e) => { e.preventDefault(); window.scrollTo({ top: 900, behavior: "smooth" }) }} className="">
                  <div className="uppercase font-normal font-semibold">
                    Podrobná specifikace
                  </div>
                  <Svg src="/icons/underline2" />
                </a>
              </div>

              <div className="absolute mobile:hidden mt-200px w-divider border-t border-dividerGold z-0" />

              <div className="notMobile:hidden w-full border-t border-dividerGold mb-24px" />

              <div className="flex justify-between mobile:flex-col notMobile:items-center font-normal">
                <div className="flex flex-col min-w-150px">
                  <div className="text-24">
                    {data.price} Kč
                  </div>
                  <div className="flex items-center mobile:mb-24px">
                    <div className="text-12">
                      Nejsme plátci DPH
                    </div>
                  </div>
                </div>
                <Button href="#demand" onClick={(e) => { e.preventDefault(); window.scrollTo({ top: 1300, behavior: "smooth" }) }} className="uppercase border-darkGreen bg-darkGreen hover:bg-mediumGold hover:border-mediumGold hover:text-darkGreen">
                  Odeslat POPTávku
                </Button>
              </div>

              <div className=" w-full border-t border-dividerGold mt-32px mb-24px" />

              <div className="flex items-center">
                <Svg src="/icons/information" className="w-32px" />
                <div className="340:w-200px">
                  zakázková výroba STANDARDNĚ do 30 dnů
                </div>
              </div>
            </div>
            <div className="  text-16">
              <div className="dmserif text-24 mobile:py-12px notMobile:pb-24px pt-88px">
                Naše hodnoty
              </div>

              <div className="flex items-center notMobile:py-12px mobile:py-24px">
                <Svg src="/icons/handmade" className="h-48px" />
                <div className="ml-16px w-80%">
                  Ruční výroba - každý výrobek v&nbsp;Lignum Nostri prochází našima rukama. Poctivá práce je&nbsp;naší prioitou
                </div>
              </div>

              <div className="notMobile:hidden w-full border-t border-dividerGold" />

              <div className="flex items-center notMobile:py-12px mobile:py-24px">
                <Svg src="/icons/quality" className="h-48px" />
                <div className="ml-16px w-80%">
                  Kvalitní materiál - pečlivě vybíráme materiál tak, aby&nbsp;kvalita a&nbsp;estetika šly ruku v&nbsp;ruce
                </div>
              </div>

              <div className="notMobile:hidden w-full border-t border-dividerGold" />

              <div className="flex items-center notMobile:py-12px mobile:py-24px ">
                <Svg src="/icons/nature" className="h-48px" />
                <div className="ml-16px w-80%">
                  Inspirace přírodou - s&nbsp;námi můžete mít svůj kousek přírody i&nbsp;v&nbsp;teple domova
                </div>
              </div>

              <div className="notMobile:hidden w-full border-t border-dividerGold" />

              <div className="flex items-center notMobile:py-12px mobile:py-24px mb-16px " id="spec">
                <Svg src="/icons/careful" className="h-48px" />
                <div className="ml-16px w-80%">
                  Šetrnost vůči přírodě - vyrábíme na&nbsp;zakázku, abychom&nbsp;eliminovali jakýkoliv odpad, výrobky označené “Old, but gold” pochází z&nbsp;opotřebovaného dřeva
                </div>
              </div>

              <div className=" w-full border-t border-dividerGold mb-24px" />

              <WhiteDropdowns title="Podrobná Specifikace" className="pb-24px" detail={data.details} />
            </div>
          </div>
          <Galery images={data.images} name={data.name} />
        </div>
        <div id="#demand"></div>
      </div>
    </>
  )
};