import { ScreenContext } from 'app/components/ScreenContext';
import React, { useContext } from 'react'
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

interface Props {}

function Header(props: Props) {
    const {} = props
    const {isMobile} = useContext(ScreenContext);
    return (
        <div>
            {
                isMobile?
                <MobileHeader />
                :
                <DesktopHeader />
            }
        </div>
    )
}

export default Header
