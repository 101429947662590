import { ScreenContext } from 'components/ScreenContext';
import React, { useContext, useState } from 'react'
import Footer from './Footer';
import Header from './Header'
import HeaderMobile from './HeaderMobile';
import Loading from './Loading';
import Modals from './Modals';

interface Props {
    children?: any
    className?: string
    products?: any
}

export default function Layout({ children, className, products }: Props) {

    const { isMobile, played, setPlayed } = useContext(ScreenContext);

    return (
        <>
            <div id="layout" className="min-h-screen min-w-full">

                {
                    isMobile
                        ? <HeaderMobile />
                        : <Header />
                }
                <main className={` z-0 relative 1441min:flex 1441min:flex-col 1441min:items-center  ${className}`}>
                    {children}
                </main>
            </div>
            <Footer />
            <Modals /> 
            {
                (played == false)&&
                <Loading setPlayed={setPlayed}  />
            }
            
        </>
    )
}
