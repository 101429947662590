import Icon from 'components/Icon'
import React, { InputHTMLAttributes, useContext, useState } from 'react'
import { FormContext } from './Form'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string | any
    name: string
}

function PasswordField(props: Props) {
    const {label, className, name, ...rest} = props

    const {data, setData, errors, clearErrors} = useContext(FormContext)
    const [show, setshow] = useState(false)

    return (
        <label className="w-full flex flex-col box-border">
            <div>{label}</div>
            <div className="relative flex items-center">
                <input 
                    className={`border border-EBE9E6 rounded-default bg-white  placeholder-C4C4C4 h-55px flex box-border w-full items-center px-19px pr-35px ${className}`}     
                    type={`${show ? "text" : "password"}`} 
                    {...rest} 
                    name={name}
                    value={data[name] ?? ""} 
                    onChange={e => {setData(name, e.target.value); clearErrors(name)}}
                />
                <Icon onClick={()=>setshow(s => !s)} className="absolute right-16px cursor-pointer" name={`${show ? 'eye-closed' : 'eye'}`} />
            </div>
            <div className={`h-12px text-12 text-C86B28 ${errors[name] ? "" : "opacity-0"}`}>{errors?.[name]}</div>
        </label>
    )
}

export default PasswordField
