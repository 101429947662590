import Button from 'app/components/Buttons/Button'
import Icon from 'app/components/Icon'
import React from 'react'

interface Props {
    className?: string
    placeholder?: string
    label?:string
    containerClassName?:string
}

function SearchBar(props: Props) {
    const {className, placeholder, label, containerClassName} = props

    return (
        <div className={(" " + containerClassName)}>
            <div className="text-12">{label}</div>
            <div className={`flex items-center h-55px ${className}`}>
                <input className={`p-15px border flex w-full h-55px border-darkBrandColor border-r-0 rounded-sm rounded-tr-none rounded-br-none`} type="text" name="search" placeholder={`${placeholder ?? "Hledejte..."}`}/>
                <div className="h-full border-r border-t border-b border-darkBrandColor px-24px flex items-center">
                    <Icon name="placeholder" className="w-24px h-24px" />
                </div>
            </div>
        </div>
    )
}

export default SearchBar
