import React, { useState, useEffect, useRef } from 'react';
import Button from './Buttons/Button';

export default function SpecPozadavky(){

  return(
    <>
      <div className="notMobile:hidden h-100px flex items-end bg-darkBrown">
        <div className=" text-24 dmserif mb-16px text-lightGold mx-16px">
          Máte speciální požadavky?
        </div>
      </div>

      <div className="notMobile:grid grid-cols-2 grid-rows-1 mobile:flex mobile:flex-col notMobile:items-center bg-darkBrown">
        <div className="w-full h-full">
          <img src="/assets/img/special.png" alt="" className="object-cover h-full w-full" />
        </div>
        <div className="max-w-xl notMobile:mx-auto mobile:mx-16px text-dividerGold mobile:pt-24px mobile:pb-48px">
          <div className="mobile:hidden text-24 dmserif mb-16px">
          Máte speciální požadavky?
          </div>
          <div className="mb-24px notMobile:w-85%">
          Nenašli jste, co&nbsp;jste hledali? Máte svou&nbsp;vlastní představu, které&nbsp;byste chtěli vdechnout život? Žádný problém - rádi&nbsp;Vám vytvoříme produkt na&nbsp;míru, nejprve&nbsp;však spolu&nbsp;vše nezávazně zkonzultujeme.
          </div>
          <Button href="/vyroba" className="uppercase hover:bg-lightGold bg-darkBrown border-dividerGold hover:text-darkGreen">
          Více o&nbsp;zakázkové výrobě
          </Button>
        </div>
      </div>
    </>
  )
};