import React, { useState, useEffect, useRef } from 'react';
import Icon from './Icon';
import ImgJpg from './ImgJpg';

export default function TakoviJsmeMy(){

  return(
    <>
      <div className="w-full border-t border-dividerGold h-48px notMobile:h-88px mobile:max-w-mobileFull"/>
      <div className="px-16px notMobile:px-160px mobile:max-w-mobileFull"> 
        <div className="text-24 dmserif pb-24px flex w-full h-full justify-center">Takoví jsme my</div>

        <div className="py-32px">
          <div className="flex items-center notMobile:justify-between w-full h-full">
            <ImgJpg src="adam" className="max-w-524px 2560min:max-w-5xl min-w-150px mobile:w-1/2 w-full "/>
            <div className="flex flex-col pl-32px">
              <div className="text-18 dmserif font-semibold">Adam Müller</div>
              <Icon name="muller" className="mt-4px"/>
              <div className="max-w-lg mt-16px mobile:hidden">Vystudoval jsem obor Umělecké a&nbsp;řemeslné zpracování dřeva a&nbsp;získal jsem zkušenosti v&nbsp;řadě zaměstnání - od&nbsp;kácení dřevin přes práci na&nbsp;stavbě až&nbsp;po&nbsp;montáž dveří a&nbsp;podlah. Zastávám&nbsp;jak zakázkovou, tak&nbsp;sériovou výrobu. Rád&nbsp;vám se&nbsp;vším poradím a&nbsp;pomůžu zvolit to&nbsp;pravé přímo pro&nbsp;vás.</div>
            </div>
          </div>
          <div className="max-w-lg mt-16px notMobile:hidden">Vystudoval jsem obor Umělecké a&nbsp;řemeslné zpracování dřeva a&nbsp;získal jsem zkušenosti v&nbsp;řadě zaměstnání - od&nbsp;kácení dřevin přes prácina&nbsp;stavbě až po&nbsp;montáž dveří a&nbsp;podlah. Zastávám jak&nbsp;zakázkovou, tak&nbsp;sériovou výrobu. Rád vám se&nbsp;vším poradím a&nbsp;pomůžu zvolit to&nbsp;pravé přímo pro&nbsp;vás.</div>
        </div>
      </div>

      <div className="pl-16px notMobile:px-160px notMobile:mb-70px">
        <div className="py-32px mobile:max-w-mobileFull">
          <div className="flex flex-row-reverse items-center notMobile:justify-between w-full h-full mobile:pr-6px">
            <ImgJpg src="julie" className="max-w-524px 2560min:max-w-5xl min-w-150px mobile:w-1/2 w-full"/>
            <div className="flex flex-col ">
              <div className="text-18 dmserif font-semibold mobile:mr-10px">Julie Baránková</div>
              <Icon name="barankova" className="mt-4px mr-4px"/>
              <div className="max-w-lg mt-16px mobile:hidden">Ke&nbsp;každému kreativci patří praktik, a&nbsp;proto jsem tu já. Starám se&nbsp;o&nbsp;administrativu a&nbsp;komunikuji s&nbsp;vámi, našimi zákazníky. V&nbsp;současné době studuji Daně a&nbsp;účetnictví na&nbsp;Univerzitě Tomáše Bati ve&nbsp;Zlíně a&nbsp;doplňuji tak&nbsp; řemeslo o&nbsp;teoretické znalosti. Neváhejte se&nbsp;na&nbsp;mě kdykoliv obrátit!</div>
            </div>
          </div>
          <div className="max-w-lg mt-16px notMobile:hidden">Ke&nbsp;každému kreativci patří praktik, a&nbsp;proto jsem tu já. Starám se&nbsp;o&nbsp;administrativu a&nbsp;komunikuji s&nbsp;vámi, našimi zákazníky. V současné době studuji Daně a&nbsp;účetnictví na&nbsp;Univerzitě Tomáše Bati ve&nbsp;Zlíně a&nbsp;doplňuji tak&nbsp;řemeslo o&nbsp;teoretické znalosti. Neváhejte se&nbsp;na&nbsp;mě kdykoliv obrátit!</div>
        </div>
      </div>
    </>
  )
};