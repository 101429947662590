import React, { useState, useEffect, useRef } from 'react';
import Svg from './Svg';


export default function MilujemeVuniDreva(){

  return(
    <>
      <div className="flex justify-center">
        <Svg src="/icons/milujeme"  className="mx-160px mobile:mx-16px 370:mt-34px mt-28px 850px:mt-48px notMobile:mb-24px w-full"/>
      </div>
    </>
  )
};