import React, { InputHTMLAttributes, useContext } from 'react'
import { FormContext } from './Form'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string | any
    name: string
    labelClassName?: string
    wraperClassName?:string
}

function TextField(props: Props) {
    const {label, className, name, labelClassName, wraperClassName, ...rest} = props

    const {data, setData, errors, clearErrors} = useContext(FormContext)

    return (
        <label className={("w-full flex flex-col box-border text-14 ") + (wraperClassName)}>
            <div className={(" ") + labelClassName}>{label}</div>
            <input 
                className={`border border-dividerGold rounded-sm bg-darkGreen placeholder-dividerGold text-white text-16 placeholder-opacity-30 h-55px flex box-border w-full items-center px-19px outline-none ${className}`}     
                type="text" 
                {...rest} 
                name={name}
                value={data[name] ?? ""} 
                onChange={e => {setData(name, e.target.value); clearErrors(name)}}
            />
            <div className={`h-12px text-12 text-redred text-opacity-70 w-full flex justify-end ${errors[name] ? "" : "opacity-0"}`}>{errors?.[name]}</div>
        </label>
    )
}

export default TextField
