import React, { useState, useEffect, useRef } from 'react';
import Button from './Buttons/Button';
import Icon from './Icon';
import Svg from './Svg';

export default function PoraditSVyberem(){

  return(
    <>
    <div className="bg-dividerGold relative py-88px mobile:py-32px">
    <Svg src="/icons/loglines2" className="absolute left-0 top-0 mobile:hidden opacity-16"/>
    <div className="flex flex-col justify-center mobile:items-start items-center mobile:mx-16px">
        <div className="text-24 dmserif pb-16px">Potřebujete poradit s výběrem?</div> 
        <div className="pb-16px font-normal notMobile:text-center max-w-lg flex">
          Rádi s&nbsp;vámi vše probereme a&nbsp;najdeme pro&nbsp;vás&nbsp;to&nbsp;pravé. Můžete&nbsp;nám&nbsp;napsat či zavolat.
        </div>
        <div className="grid grid-cols-2 w-full min-h-129px h-full max-w-md pr-16px">
          <div className="hmax-w-179px max-h-145px relative h-full w-full ">
            <div className="340:h-100px h-129px max-w-163px w-full absolute border border-mediumGold ml-16px mt-16px rounded-sm"/>
            <img src="assets/img/juliesmall.jpg" className="absolute row-start-1 col-start-1 rounded-sm"/>
          </div>
          <div className="justify-self-start mobile:max-w-mobileFull pl-32px 370:mt-20px mt-40px">
            <div className=" text-18 dmserif mobile:max-w-mobileFull">Julie Baránková</div>
            <Svg src="/icons/barankova" className="mt-10px"/>
          </div>
        </div>
        <Button href="/vyroba#contact" className="bg-dividerGold text-darkGreen 340:px-0 340:text-12 340:mt-30px 370:mt-42px mt-60px mobile:min-w-mobileFull">
            <Icon name="mail" className=" mr-16px h-24px"/>
          vyroba@lignumnostri.cz
        </Button>
      </div>
    </div>
    </>
  )
};