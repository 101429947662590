import { InertiaLink } from '@inertiajs/inertia-react';
import Map from 'app/fragments/Map/Map';
import React, { useState, useEffect, useRef } from 'react';
import Button from './Buttons/Button';
import Icon from './Icon';
import Svg from './Svg';

export default function KontaktLanding(){

  return(
    <>
      <div className="notMobile:pt-100px relative mobile:py-16px ">
        <div className="flex justify-between mobile:flex-col">
          <div className="flex flex-col  max-w-lg mx-16px notMobile:ml-160px notMobile:mr-100px 1920min:mr-160px">
            <div className="text-darkBrown text-h32 dmserif notMobile:mb-32px">
            Neváhejte se na&nbsp;nás obrátit
            </div>
            <div className="mobile:hidden w-divider border-t border-dividerGold"/>

            <div className="flex flex-col w-full pb-10px font-semibold mobile:text-16">
              <div className="flex mobile:flex-col notMobile:py-16px">
                <a href="tel:+420777705422" className="flex items-center">
                  <img src="/assets/img/mobil.png"/>
                  <div className="py-16px pl-10px w-200px underline">+420 777 705 422</div>
                </a>
                <a href="mailto:vyroba@lignumnostri.cz?subject=Ahoj" className="flex items-center notMobile:py-16px">
                  <Icon name="mail" className=""/>
                  <div className="py-16px pl-10px underline">vyroba@lignumnostri.cz</div>
                </a>
              </div>
            </div>

            <Button href="https://m.me/lignumnostri" target="_blank" className="bg-bg text-darkGreen w-300px hover:bg-lightGold mobile:max-w-mobileFull mobile:mb-24px">
              <Svg src="/icons/messenger" className="mr-16px h-24px font-semibold"/>
              Napsat na messenger
            </Button>
          </div>
          <div className="flex w-full ">
            <div className="grid grid-cols-1 grid-rows-1 mobile:w-full w-full h-415px mobile:h-235px notMobile">
              <div className="mobile:hidden col-start-1 row-start-1 w-full border border-mediumGold bg-bg"/>
              <Map className="col-start-1 row-start-1 notMobile:-ml-16px notMobile:-mt-16px notMobile:mr-16px h-415px mobile:h-235px"/>
            </div>
            <div className="mobile:hidden w-160px"></div>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-start mb-88px mobile:mb-48px">
        <div className=" mobile:mx-16px mx-160px max-w-lg">
          <div className="dmserif text-24 mb-16px">
          Vyrábíme kousek od Olomouce, dovážíme&nbsp;po&nbsp;celé republice
          </div>
          <div className="flex mobile:flex-col justify-between">
            <div className="flex notMobile:flex-row-reverse justify-between">
              <div className="">
                <div className="font-semibold">Lignum Nostri</div>
                <div className="font-semibold">Adam Müller</div>
                <div className="">IČ: 11641827</div>
              </div>
              <Svg src="/icons/logocircle"  className="notMobile:mr-24px"/>
            </div>
            <div className="mobile:mt-24px">
              <div className="">Bartošova 1729/9</div>
              <div className="">750 02 Přerov</div>
              <div className="">Česká republika</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};