import React, { useState, useEffect, useRef } from 'react';
import Button from './Buttons/Button';
import ImgJpg from './ImgJpg';
import Svg from './Svg';

export default function NasPribeh(){

  return(
    <>
      <div className=" relative notMobile:py-88px mobile:mt-56px mobile:mb-40px">
        <Svg src="/icons/logwlogo" className="absolute bottom-0 right-0 z-0 mobile:hidden"/>
        <div className="relative flex mobile:flex-col notMobile:justify-evenly items-center z-50 w-divider mobile:w-mobileFull mx-16px notMobile:mx-160px">
          <ImgJpg src="team" className="mr-16px max-w-550px w-full 2560min:max-w-5xl" />
          <div className="max-w-448px notMobile:ml-80px">
            <div className="dmserif text-24 mb-16px">
              Náš příběh
            </div>
            <div className="text-16 mb-24px mr-24px mobile:max-w-mobileFull">
            Dva lidé, dřevo, řemeslo a&nbsp;láska k&nbsp;nim. Tak by se náš příběh dal shrnout v&nbsp;kostce. Rádi vám povíme více.
            </div>
            <Button href="/pribeh" className="uppercase bg-lightGold border border-lightGold text-darkGreen mobile:w-full mobile:mb-24px" >
            pŘEČTĚTE SI NÁŠ PŘÍBĚH
            </Button>
          </div>
        </div>
        <div className="" id="contact"></div>
      </div>
    </>
  )
};