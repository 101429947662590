import { InertiaLink, InertiaLinkProps } from '@inertiajs/inertia-react'
import React from 'react'

export interface LinkProps extends InertiaLinkProps {
    defaultColor?: boolean
    defaultPadding?: boolean
    reverse?:boolean
}

function Button(props: LinkProps) {
    const {className, defaultColor = true, defaultPadding = true, reverse=false, ...rest} = props

    return (
        (rest.href.includes("mailto:") || rest.href.includes("tel:") || rest.href.includes("https://m.me/") || rest.href.includes("mail:to")) ?
        <a href={props.href} className={`h-50px font-normal transform duration-300 border font-semibold ${defaultColor && ( reverse?"text-darkGreen bg-white hover:bg-darkBrandColor border-mediumGold hover:border-white hover:text-white ":"text-white bg-darkBrandColor border-mediumGold hover:bg-white")}   rounded-sm ${defaultPadding && "px-22px"} inline-flex items-center justify-center font-normal ${className}`} >
            {props.children}
        </a>
        :
        <InertiaLink className={`h-56px font-normal transform duration-300 border font-semibold ${defaultColor && ( reverse?"text-darkGreen bg-white hover:bg-darkBrandColor border-mediumGold hover:border-white hover:text-white ":"text-white bg-darkBrandColor border-mediumGold hover:bg-white")}   rounded-sm ${defaultPadding && "px-22px"} inline-flex items-center justify-center font-normal ${className}`} {...rest} />
    )
}

export default Button
