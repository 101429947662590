import React, { useState, useEffect, useRef } from 'react';
import Img from './Image';
import Svg from './Svg';

export default function VyrobaSteps(){

  return(
    <>

      <div className="">
        <div className="notMobile:py-100px relative mobile:py-48px flex justify-between items-center mobile:flex-col-reverse mx-16px notMobile:mx-160px">
          <div className="max-w-550px w-full 2560min:max-w-5xl mobile:mr-16px">
            <div className={" relative "}>
              <div className="absolute w-full h-full left-0 top-0 ml-20px mt-20px border border-mediumGold rounded-sm"/>
              <Img src={`/assets/img/konzultace.jpg`} className="z-10 relative object-contain h-full w-full rounded-sm"/>
            </div>
          </div>
          <div className="flex">
            <div className="h-52px w-52px notMobile:px-88px mobile:mr-16px">
              <div className="relative">
                <div className="absolute  bg-mediumGold rounded-sm text-darkBrown flex justify-center text-18 dmserif items-center w-48px h-48px">
                  1.
                </div>
                <div className="absolute ml-4px mt-4px w-48px h-48px border border-mediumGold rounded-sm"/>
              </div>
            </div>
            <div className="flex flex-col  max-w-lg mt-16px mobile:ml-30px">
              <div className=" text-18 font-semibold dmserif">
              Konzultace
              </div>
              <div className=" mt-24px mb-32px">
              Spojíme se telefonicky či přes e-mail, nastíníte&nbsp;nám&nbsp;vaši&nbsp;představu a&nbsp;společně vše probereme tak, abychom&nbsp;dosáhli vaší&nbsp;spokojenosti. Domluvíme se&nbsp;na&nbsp;materiálu, designu, úpravách a&nbsp;mnohém dalším.
              </div>
            </div>
          </div>
        </div>

        



        <div className="notMobile:py-100px relative mobile:py-48px flex justify-between items-center mobile:flex-col mx-16px notMobile:mx-160px">
          <div className="flex">
            <div className="h-52px w-52px notMobile:pr-88px mobile:mr-16px">
              <div className="relative">
                <div className="absolute  bg-mediumGold rounded-sm text-darkBrown flex justify-center text-18 dmserif items-center w-48px h-48px">
                  2.
                </div>
                <div className="absolute ml-4px mt-4px w-48px h-48px border border-mediumGold rounded-sm"/>
              </div>
            </div>
            <div className="flex flex-col  max-w-lg mt-16px mobile:ml-30px">
              <div className=" text-18 font-semibold dmserif">
              Návrh
              </div>
              <div className=" mt-24px mb-32px mr-24px">
              Podle domluvy vám buď vytvoříme návrh v digitální podobě, anebo&nbsp;vám sdělíme technické parametry. Doladíme&nbsp;detaily a&nbsp;můžeme směle přejít ke&nbsp;třetímu kroku.
              </div>
            </div>
          </div>
          <div className="max-w-550px 2560min:max-w-5xl mobile:mr-16px w-full">
            <div className={" relative "}>
              <div className="absolute w-full h-full left-0 top-0 ml-20px mt-20px border border-mediumGold rounded-sm"/>
              <Img src={`/assets/img/navrh.jpg`} className="z-10 relative object-contain h-full w-full rounded-sm"/>
            </div>
          </div>
        </div>




        <div className="notMobile:py-100px relative mobile:py-48px flex justify-between items-center mobile:flex-col-reverse mx-16px notMobile:mx-160px">
          <div className="max-w-550px 2560min:max-w-5xl mobile:mr-16px w-full">
            <div className={" relative "}>
              <div className="absolute w-full h-full left-0 top-0 ml-20px mt-20px border border-mediumGold rounded-sm"/>
              <Img src={`/assets/img/vyroba.jpg`} className="z-10 relative object-contain h-full w-full rounded-sm"/>
            </div>
          </div>
          <div className="flex">
            <div className="h-52px w-52px notMobile:px-88px mobile:mr-16px">
              <div className="relative">
                <div className="absolute  bg-mediumGold rounded-sm text-darkBrown flex justify-center text-18 dmserif items-center w-48px h-48px">
                  3.
                </div>
                <div className="absolute ml-4px mt-4px w-48px h-48px border border-mediumGold rounded-sm"/>
              </div>
            </div>
            <div className="flex flex-col  max-w-lg mt-16px mobile:ml-30px">
              <div className=" text-18 font-semibold dmserif">
              Výroba
              </div>
              <div className=" mt-24px mb-32px">
              Zde přichází na&nbsp;řadu samotný proces výroby. Pečlivě opracujeme dřevo, upravíme&nbsp;ho&nbsp;dle vašich&nbsp;představ a&nbsp;zhmotníme vaši&nbsp;představu do&nbsp;originálního kusu nábytku či&nbsp;doplňku.
              </div>
            </div>
          </div>
        </div>


        <div className="notMobile:py-100px relative mobile:py-48px flex justify-between items-center mobile:flex-col mx-16px notMobile:mx-160px">
          <div className="flex">
            <div className="h-52px w-52px notMobile:pr-88px mobile:mr-16px">
              <div className="relative">
                <div className="absolute  bg-mediumGold rounded-sm text-darkBrown flex justify-center text-18 dmserif items-center w-48px h-48px">
                  4.
                </div>
                <div className="absolute ml-4px mt-4px w-48px h-48px border border-mediumGold rounded-sm"/>
              </div>
            </div>
            <div className="flex flex-col  max-w-lg mt-16px mobile:ml-30px">
              <div className=" text-18 font-semibold dmserif">
              Montáž
              </div>
              <div className=" mt-24px mb-32px mr-24px">
              Zbývá nám poslední krok, a to&nbsp;montáž. Podle domluvy vám&nbsp;buďto výrobek sami&nbsp;sestavíme, nebo&nbsp;vám&nbsp;poradíme, jak&nbsp;na&nbsp;to. A&nbsp;je&nbsp;to! Nyní si&nbsp;můžete naplno užívat svého&nbsp;nového kousku.
              </div>
            </div>
          </div>
          <div className="max-w-550px 2560min:max-w-5xl mobile:mr-16px w-full">
            <div className={" relative "}>
              <div className="absolute w-full h-full left-0 top-0 ml-20px mt-20px border border-mediumGold rounded-sm"/>
              <Img src={`/assets/img/montaz.jpg`} className="z-10 relative object-contain h-full w-full rounded-sm"/>
            </div>
          </div>
        </div>
      </div>

    </>
  )
};