import DoubleDatePicker from 'app/admin/components/DoubleDatePicker'
import Layout from 'app/admin/fragments/Layouts/Layout'
import LineGraph from 'app/admin/fragments/Layouts/LineGraph'
import StatContainer from 'app/admin/fragments/Layouts/StatContainer'
import WhiteContainer from 'app/admin/fragments/Layouts/WhiteContainer'
import InvertedButton from 'app/components/Buttons/InvertedButton'
import { ProductType } from 'app/components/Types/ProductType'
import React from 'react'


interface Props {
    products: Array<ProductType>
 }

function Index(props: Props) {
    const { products} = props

    return (
        <Layout>
            <div className="pb-130px flex justify-center py-30px">
                <div className=" max-w-4xl w-full">
                    <h2 className="text-h32 text-darkBrandColor font-bold mb-24px mt-40px">Products</h2>
                    {
                        products?.map(p =>
                                <div className="p-16px bg-white shadow-lg text-12 border-lightGray border rounded-default flex justify-start my-12px items-center leading-tight">
                                    <div className="px-16px border-r text-16 border-lightBlue border-dashed whitespace-nowrap text-darkBrandColor font-semibold">{p.name}</div>
                                    <div className="px-16px border-r border-lightBlue border-dashed">{p.sub}</div>
                                    <div className="px-16px border-r border-lightBlue border-dashed break-all flex-shrink">{p.description}</div>
                                    <div className="flex flex-grow flex-shrink-0 justify-end ml-20px">
                                        {
                                            p.images.map(im => 
                                                <div className="p-4px bg-lightGray rounded-default shadow-lg ml-8px border border-lightBlue">
                                                    <img className="h-50px" src={`/storage/${im}`} alt={im} />    
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Index

