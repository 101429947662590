import Layout from 'app/admin/fragments/Layouts/Layout'
import Icon from 'app/components/Icon'
import React from 'react'

interface Props {
    products: Array<any>
}

function Index(props: Props) {
    const {products} = props

    return (
        <Layout>
            <div>
                {
                    products.map(p => 
                        <div className="flex border-b border-darkBrown p-12px gap-12px">
                            <div>{p.name}</div>
                            <div><Icon name={p.icon}/></div>
                            <div>{p.description}</div>
                            <div>{p.price}</div>
                            <div>{p.category_id}</div>
                            <div>{p.name}</div>
                            <div>
                                {
                                    p.images.map( im =>
                                        <img className="w-50px h-50px object-cover object-center" sizes="50px" srcSet={im.srcset} src={im.url} width={im.width} height={im.height} />    
                                    )
                                }
                            </div>
                        </div>    
                    )
                }
            </div>
        </Layout>
    )
}

export default Index
