import Button from 'components/Buttons/Button'
import IconText from 'fragments/IconText'
import { Warning } from 'postcss'
import React, { InputHTMLAttributes, useContext } from 'react'
import { FormContext } from './Form'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label?: string | any
}

function FileInput(props: Props) {
    const { label, name = "file", ...rest } = props

    const { data, setData, errors, clearErrors } = useContext(FormContext)

    let fileList = data?.[name];

    if(name.includes('[]'))
        throw new Error("Dont include [] in input name for multiple files, just add prop 'multiple | multiple={true}' ");
    

    return (
        <label className="cursor-pointer">
            <div>{label}</div>
            <input
                className="opacity-0 w-0 h-0 absolute"
                type="file"
                {...rest}
                name={`${name}[]`}
                onChange={e => {setData(name, e.target.files); clearErrors(name)}}
            />
            <div className="flex items-center mob:flex-col mob:items-start">
                <Button className="pointer-events-none mob:w-full text-left justify-start" href="/noJS" onClick={e => { e.preventDefault(); }}><IconText icon="upload" text="Nahrát soubor" /></Button>
                <div className="ml-20px text-12 mob:ml-0 mob:mt-8px">
                    {
                        (fileList?.length >  0)
                        ? (
                            Object.values(fileList)?.map((f: any) =>
                                `${f?.name} (${Math.floor(f?.size / 1000 / 10)/100} Mb)` 
                            ).join(', ')
                        
                        )
                        
                        : "Maximální velikost souboru je 5Mb"
                    }
                 </div>
            </div>
            <div className={`h-12px text-12 text-C86B28 ${errors[name] ? "" : "opacity-0"}`}>{errors?.[name]}</div>
        </label>
    )
}

export default FileInput
