import Layout from "components/Layouts/Layout";
import React, { useContext, useState } from "react";
import { ScreenContext } from "components/ScreenContext";
import { InertiaLink, useForm } from "@inertiajs/inertia-react";
import Modals, { MODALS, ModalsContext } from "app/components/Layouts/Modals";
import Svg from "app/components/Svg";
import VyrobaLanding from "app/components/VyrobaLanding";
import VyrobaSteps from "app/components/VyrobaSteps";
import SocialsBrown from "app/components/SocialsBrown";
import ProductHistory from "app/fragments/ProductHistory";
import KontaktGrid3 from "app/components/KontaktGrid3";


interface Props{
  className?: string,
}


export default function Home(props: Props) {
  const { isMobile } = useContext(ScreenContext);
  const {open} = useContext(ModalsContext)
  const form = useForm({});
  const {data} = form;
  return (
    <Layout>
        <div className="bg-darkBrown w-full h-full text-lightGold text-16">
          <VyrobaLanding /> 
          <VyrobaSteps />
            <div className=" border-b border-dividerGold opacity-16"/> 

            <div className="mx-160px mobile:mx-16px text-16 pb-88px pt-48 notMobile:pt-88px">
              <div className="dmserif text-24 mobile:py-12px notMobile:pb-24px">
                Proč právě my?
              </div>

              <div className="mobile:flex-col notMobile:grid grid-cols-2 grid-rows-2">

                <div className="flex w-full items-center notMobile:py-12px mobile:py-24px">
                  <Svg src="/icons/handmade" className="h-48px"/>
                  <div className="ml-16px w-80%">
                  Ruční výroba - Každý výrobek v Lignum Nostri prochází našima&nbsp;rukama. Poctivá práce je&nbsp;naší prioitou.
                  </div>
                </div>
                <div className="notMobile:hidden w-full border-t border-dividerGold opacity-16"/>
                
                <div className="flex items-center notMobile:py-12px mobile:py-24px">
                  <Svg src="/icons/nature" className="h-48px"/>
                  <div className="ml-16px w-80%">
                  Inspirace přírodou - S&nbsp;námi můžete mít&nbsp;svůj kousek přírody i&nbsp;v&nbsp;teple domova.
                  </div>
                </div>
                <div className="notMobile:hidden w-full border-t border-dividerGold opacity-16"/>

                <div className="flex items-center notMobile:py-12px mobile:py-24px">
                  <Svg src="/icons/quality" className="h-48px"/>
                  <div className="ml-16px w-80%">
                  Kvalitní materiál - Pečlivě vybíráme materiál tak, aby&nbsp;kvalita a&nbsp;estetika šly ruku v&nbsp;ruce.
                  </div>
                </div>
                <div className="notMobile:hidden w-full border-t border-dividerGold opacity-16"/>

                <div className="flex items-center notMobile:py-12px mobile:py-24px">
                  <Svg src="/icons/careful" className="h-48px"/>
                  <div className="ml-16px w-80%">
                  Šetrnost vůči přírodě - Vyrábíme na&nbsp;zakázku, abychom&nbsp;eliminovali jakýkoliv&nbsp;odpad a&nbsp;výrobky označené “Old, but&nbsp;gold” pochází z&nbsp;opotřebovaného dřeva.
                  </div>
                </div>
              </div>
              <div className="notMobile:hidden border-b border-dividerGold opacity-16"/>
            </div>

            <ProductHistory />

            <KontaktGrid3/>
            <SocialsBrown />
        </div>  
    </Layout>
  );
}