import React, { TextareaHTMLAttributes, useContext } from 'react'
import { FormContext } from './Form'

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    label?: string
    name: string
    labelClassName?:string
}

function TextArea(props: Props) {
    const { label, className, labelClassName, name, ...rest } = props
    const {data, setData, errors, clearErrors} = useContext(FormContext)

    return (
        <label className="w-full">
            <div className={("text-14 ") + labelClassName}>{label}</div>
            <textarea

                className={`border resize-none placeholder-C4C4C4 border-EBE9E6 text-14 rounded-sm bg-white h-170px mob:h-120px flex box-border w-full items-center p-19px ${className}`}
                {...rest}
                name={name}
                value={data[name] ?? ""}
                onChange={e => {setData(name, e.target.value); clearErrors(name)}}
            >

            </textarea>
            <div className={`h-12px text-12 text-redred text-opacity-70 w-full flex justify-end ${errors[name] ? "" : "opacity-0"}`}>{errors?.[name]}</div>
        </label>
    )
}

export default TextArea

