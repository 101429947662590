import Icon from 'components/Icon';
import React, { useEffect, useRef, useState } from 'react';

interface Props {
    title?: string
    className?: string
    classNameActive?: any
    textColor?: string
    detail?: string
}

export default function WhiteDropdowns({ title, detail, className, textColor = "text-black", classNameActive = (a) => "" }: Props) {

    // const [open, setopen] = useState(false)
    const ref = useRef<HTMLDivElement>(null);
    const height = useRef("0px");
    const [active, setactive] = useState(true)

    const collapseSection = () => {
        let element = ref.current;
        if (element) {
            // get the height of the element's inner content, regardless of its actual size
            var sectionHeight = element.scrollHeight;

            // temporarily disable all css transitions
            var elementTransition = element.style.transition;
            element.style.transition = '';

            // on the next frame (as soon as the previous style change has taken effect),
            // explicitly set the element's height to its current pixel height, so we 
            // aren't transitioning out of 'auto'
            requestAnimationFrame(function () {
                if (element) {
                    height.current = sectionHeight + 'px';
                    element.style.height = height.current;
                    element.style.transition = elementTransition;
                }

                // on the next frame (as soon as the previous style change has taken effect),
                // have the element transition to height: 0
                requestAnimationFrame(function () {
                    if (element) {
                        height.current = 0 + 'px';
                        element.style.height = height.current;
                    }
                });
            });

            setactive(false);
        }
    };

    const expandSection = () => {
        let element = ref.current;
        if (element) {
            // get the height of the element's inner content, regardless of its actual size
            var sectionHeight = element.scrollHeight;

            // have the element transition to the height of its inner content
            height.current = sectionHeight + 'px';
            element.style.height = height.current;

            // when the next css transition finishes (which should be the one we just triggered)
            element.addEventListener('transitionend', removeTransition);
            setactive(true)
        }
    };

    const removeTransition = (e) => {
        let element = ref.current;
        if (element) {
            // remove this event listener so it only gets triggered once
            element.removeEventListener('transitionend', removeTransition);

            // remove "height" from the element's inline styles, so it can return to its initial value
            element.style.height = "initial";
        }
    };

    const toggle = () => {
        var section = ref.current;

        if (section) {
            if (!active)
                expandSection();
            else
                collapseSection();
        }
    };

    useEffect(() => {
    //    collapseSection()
    }, [])

    return (
        <div className={` mb-16px rounded-default transition-all duration-300 w-full ${active ? ' bg-bg ' : ''} ${className} ${classNameActive(active)}`}>
            <div onClick={toggle} className={`py-8px mb-8px flex justify-between items-center w-full cursor-pointer`}>
                <div className={`flex items-center flex-grow-0 w-full dmserif text-24 ${ active ? "text-black" : textColor} font-semibold`}>        
                    {title}
                </div>
                { <Icon name="dropdown-arrow" className={`transition-transform duration-300 ${active ? 'transform rotate-180 text-black' : ''}`} />}
            </div>
            <div ref={ref} className={`overflow-hidden collapsable transition-colors duration-300  ${active ? 'textblack' : ' pointer-events-none text-bg'}`}>
                <div className="mr-16px">{detail}</div>
            </div>
        </div>
    );
}
