import { useForm } from '@inertiajs/inertia-react';
import Layout from 'app/admin/fragments/Layouts/Layout'
import { ProductType } from 'app/components/Types/ProductType';
import FileInput from 'app/fragments/Forms/FileInput';
import Form from 'app/fragments/Forms/Form'
import Select from 'app/fragments/Forms/Select';
import Submit from 'app/fragments/Forms/Submit';
import TextField from 'app/fragments/Forms/TextField';
import React from 'react'
import route from 'ziggy-js';

interface Props {
    products: Array<ProductType>
 }

export default function New(props: Props) {
    const { products} = props

    const form = useForm({});
    const {data, post, reset} = form;

    const submit = (e) => {
        e.preventDefault()
        post(route('new-product'), {
            onSuccess: () => { reset(); }
        })
    }

    return (
        <Layout>
            <div className="flex justify-center">
               
                <Form form={form} onSubmit={submit} className="max-w-2xl w-full">
                    <h2 className="text-h32 text-darkBrandColor font-bold mb-24px mt-40px">Add new product</h2>
                    <TextField name="name" label="Title" placeholder="Title" />
                    <TextField name="sub" label="Subtitle" placeholder="Subtitle" />
                    <TextField name="description" label="Description" placeholder="Description" />
                    <Select
                        name="product"
                        placeholder="Parent product"
                        options={
                            products.map(p => ({text: p.name, value: `${p.id}`}))
                        }
                    />
                    <FileInput name="images" label="Images" multiple />
                    <Submit  value="Save"/>
                </Form>
            </div>
        </Layout>
    )
}
