import { useForm } from '@inertiajs/inertia-react';
import Layout from 'app/admin/fragments/Layouts/Layout'
import FileInput from 'app/fragments/Forms/FileInput';
import Form from 'app/fragments/Forms/Form';
import ImageInput from 'app/fragments/Forms/ImageInput';
import Select from 'app/fragments/Forms/Select';
import Submit from 'app/fragments/Forms/Submit';
import TextField from 'app/fragments/Forms/TextField';
import React from 'react'
import route from 'ziggy-js';
import products from '.';

interface Props {
    categories: Array<any>
}

function Create(props: Props) {
    const {categories} = props

    const form = useForm({});
    const {data, post, reset, processing} = form;

    const submit = (e) => {
        e.preventDefault()
        post(route('products.store'), {
            onSuccess: () => { reset(); }
        })
    }

    return (
        <Layout>
            <div className="flex justify-center text-white bg-brown">          
               <Form form={form} onSubmit={submit} className="max-w-2xl w-full">
                   <h2 className="text-h32 text-darkBrandColor font-bold mb-24px mt-40px">Add new product</h2>
                   <TextField name="name" label="Title" placeholder="Title" />
                   <TextField name="icon" label="Icon" placeholder="Icon" />
                   <TextField name="description" label="Description" placeholder="Description" />
                   <TextField name="price" label="Price" placeholder="Price"/>
                   <Select
                        name="category_id"
                        placeholder="Parent product"
                        options={
                            categories.map(p => ({text: p.name, value: `${p.id}`}))
                        }
                    />
                 
                   <ImageInput name="images" label="Images" multiple className="mt-200px"/>
                   <TextField name="details" label="details" placeholder="Details"/>
                   <TextField name="parameters" label="Parameters" placeholder="Parameters"/>
                   <TextField name="height" label="Height" placeholder="Height"/>
                   <TextField name="material" label="Material" placeholder="Material"/>
                   <TextField name="surface" label="Surface" placeholder="Surface"/>
                   <TextField name="table_base_type" label="Podnož stolu" placeholder="Podnož stolu"/>
                   <Submit  value={`${processing ? "processing" : "Save"}`} className={` ${processing && "bg-redred "} `}/>
               </Form>
           </div>
        </Layout>
    )
}

export default Create
