import Button from 'app/components/Buttons/Button'
import Icon from 'app/components/Icon'
import Layout from 'app/components/Layouts/Layout'
import React from 'react'

interface Props {
    status: number
}

function ErrorPage(props: Props) {
    const {status} = props

    const title = {
        503: 'Service Unavailable',
        500: 'Server Error',
        404: 'Stránka nenalezena',
        410: 'Stránka smazána',
        403: 'Nepovoleno',
        999: 'Javascript není zapnutý'
    }[status]

    const description = {
        503: 'Sorry, we are doing some maintenance. Please check back soon.',
        500: 'Vypadá to, že došlo k chybě. Prosím, vraťte se zpět na úvodní stránku.',
        404: 'Vypadá to, že došlo k chybě. Prosím, vraťte se zpět na úvodní stránku.',
        410: 'Stránka smazána. Prosím, vraťte se zpět na úvodní stránku.',
        403: 'Nemáte přístup k této stránce.',
        999: 'Prosím zapněte si Javascript pro plnou funkčnost webové stránky'
    }[status]

    return (
        <Layout>
            <div className='flex items-center justify-center min-h-screen'>
                <div className="flex flex-col items-center mt-80px mob:px-24px mob:mt-24px mob:z-10">
                    {
                        status != 999
                            ? <h1 className="text-128 font-bold">{status}</h1>
                            : <h1 className="text-128 font-bold flex items-center gap-30px">Javascript <img className="h-80px my-16px rounded" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/512px-Unofficial_JavaScript_logo_2.svg.png" alt="Javascript | Serafin byliny" /></h1>
                    }
                    <h2 className="text-36 mt-0 mb-32px font-bold mob:text-center">{title}</h2>
                    <div className="mob:text-center">{description}</div>
                    <Button href="/" defaultColor={false} className="bg-darkBrown text-white mt-32px sm:w-full"><Icon name="home" className="mr-16px" />Zpět na úvodní stránku</Button>
                </div>
            </div>
        </Layout>
    )
}

export default ErrorPage
