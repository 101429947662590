import Icon from 'components/Icon'
import React, { HTMLProps, useContext } from 'react'
import { FormContext } from './Form'

interface Props extends HTMLProps<HTMLInputElement> {
    label?: string | any
}

function Radio(props: Props) {
    const { className, name = "radio", label, ...rest } = props

    const { data, setData, errors, clearErrors } = useContext(FormContext)

    return (
        <label className={`flex flex-col relative cursor-pointer ${className}`} >
            {/* <input
                className="h-0 w-0 opacity-0 absolute"
                {...rest}
                type="radio"
                name={name}
                value={data[name] ?? ""}
                onChange={e => { setData(name, e.target.value); clearErrors(name) }}
            />
            <div className="border border-EBE9E6 rounded-full w-24px h-24px bg-white radio flex items-center justify-center" >
                <div className="bg-white w-10px h-10px hidden rounded-full" />
            </div> */}
            <div className="flex items-center flex-shrink-0">
                <input
                    className="h-0 w-0 opacity-0 absolute"
                    {...rest}
                    type="radio"
                    name={name}
                    // value={data[name] ?? ""}
                    onChange={e => { setData(name, e.target.value); clearErrors(name) }}
                />
                <div className="border border-EBE9E6 rounded-full w-24px h-24px bg-white radio flex items-center justify-center  flex-shrink-0" >
                    <div className="bg-white w-10px h-10px hidden rounded-full" />
                </div>
                <div className="ml-16px leading-6 w-full">{label}</div>
            </div>
            <div className={`h-12px text-12 text-C86B28 ${errors[name] ? "" : "opacity-0"}`}>{errors?.[name]}</div>
        </label>
    )
}

export default Radio
