import React, { useContext, useEffect, useRef, useState } from 'react'
import { ScreenContext } from '../ScreenContext'
interface LoadingProps {
    setPlayed:Function
}
export default function Loading(props: LoadingProps) {
    let {setPlayed} = props
    let {isMobile} = useContext(ScreenContext)
    const value = useRef(undefined as any)

    useEffect(() => {
        setTimeout(() => {
            if(value.current){
                setPlayed(true)
            }
        }, 5000);
        return () => {
        }
    }, [])

    useEffect(() => {
        value.current = isMobile
    }, [isMobile])

    return (
        <div className="fixed w-full h-screen z-99999 top-0 left-0 bg-white mobile:bg-whitish items-center">
            {
                isMobile? 
                <div className="w-full h-full m-auto mt-263px" >
                    <img src="/assets/img/lignum.gif"/>
                </div>
                :
                <video src="/assets/video/lignum.mp4" onEnded={()=>{setPlayed(true)}} autoPlay muted className=" h-1/3 w-1/3 mobile:w-full mobile:h-full m-auto 1149px:mt-263px 1920min:mt-300px "/>
            }
        </div>
    )
}