import { InertiaLink } from '@inertiajs/inertia-react'
import Icon from 'app/components/Icon'
import React from 'react'
import route from 'ziggy-js';

interface SLProps {
    text: string
    href: string
    active?: boolean
    icon: string
}


function SideLink({text, href, icon, active}: SLProps) {
    return (
        <InertiaLink href={href}>
            <div className={"flex p-16px rounded-default items-center " + (active ? " bg-EBE9E6 text-green" : " ")}>
                <Icon className="h-24px" name={icon} />
                <div className="ml-8px text-12 font-normal">{text}</div>
            </div>
        </InertiaLink>
    );
}

interface Props {

}


function SideMenu(props: Props) {
    const { } = props
    let url = window.location.pathname
    return (
        <div className="bg-bg border-r border-C4C4C4 h-auto px-16px pt-16px pb-85px min-h-screen min-w-max">
            <SideLink text="Dashboard" href="/admin" active icon="globe" />
            <SideLink text="New Product" href={route('products.create')} icon="plus" />
        </div>
    )
}

export default SideMenu
