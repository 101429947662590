import React, { useState, useEffect, useRef } from 'react';
import Svg from './Svg';

interface Props {
  className?: string,
  src?: string,
}


export default function ImgSvg({src, className="", ...props}: Props){

  return(
    <>
      <div className={` relative mobile:mb-40px notMobile:mr-20px ${className}`}>
        <Svg src="/icons/rectangle" className="absolute  w-full h-full left-0 top-0 ml-20px mt-20px mobile:ml-16px mobile:mt-16px z-0"/>
        <img src={`/assets/img/${src}.jpg`} className="z-10 relative h-full w-full rounded-sm"/>
      </div>
    </>
  )
}
