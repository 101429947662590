import React, { useState, useEffect, useRef } from 'react';
import Icon from './Icon';
import MilujemeVuniDreva from './MilujemeVuniDreva';

interface Props {
}
function DispImage({ image, i, rendered, setRendered }) {
    const [isPhoto, setIsPhoto] = useState(false)
    var xhttp = new XMLHttpRequest();
    xhttp.open('HEAD', image.media_url);
    xhttp.onreadystatechange = async function () {
        if (this.readyState == this.DONE) {
            if (this.getResponseHeader("Content-Type") == "image/jpeg") {
                setIsPhoto(true)
                if (rendered < 4) {
                    setRendered(rendered + 1)
                }
            }
            else {
            }
        }
    };
    xhttp.send();
    return (
        <div className={(isPhoto ? "" : "hidden")} key={"igelem"+ i}>
          {
                isPhoto &&
                <a target="_blank" href={image.permalink} className="">
                    <div key={"igimage" + i} className={((4 == i) && "md:hidden ") + " w-full h-25w mobile:h-50w relative pb-full"} style={{ backgroundImage: "url(" + image.media_url + ")", backgroundSize: "cover" }}></div>
                </a>
            } 
        </div>
    )
}

export default function InstagramQuestionSection(props: Props) {
  const [data, setData] = useState(null as any)
  const [rendered, setRendered] = useState(0)
  async function getFeed() {
      const response = await fetch("https://graph.instagram.com/me/media?fields=media_url,thumbnail_url,permalink&access_token=IGQVJXRHI1ZA3l6YjZAtejJveVZAaWFd3d1ZAGOUw4SHBsbmNLc2ljLTRCUW14aVF3azlTTThfOURzaEZAtWjNocFRGbUdwOXNJblRBN1NpNjhxVTZAqMHBIYmFvRkxIXy1UaEtSdDhoQTB3bU83OWw5QU9MOQZDZD")
      const json = await response.json()
      const array = await json.data
      let new_array = []
      setData(array)
  }
  useEffect(() => {
      getFeed()
  }, [])
  const { } = props
  return (
    <>
      <MilujemeVuniDreva />
      <div className="flex flex-col w-full">
        <div className="h-56px flex justify-between mobile:justify-items-start items-center notMobile:mx-160px">
          <div className="text-16 ml-16px">
          U nás se něco pořád děje. Sledujte nás!
          </div>
          <div className="flex text-darkGreen mobile:hidden">
            <div className="h-56px pb-6px">
              <a target="_blank" href="https://www.instagram.com/lignumnostri/">
                <div className="uppercase h-full flex text-16 items-center border text-lightGold border-lightGold p-10px ml-10px cursor-pointer">
                  <Icon name="ig" className="mr-18px h-30px "/>
                  instagram
                </div>
              </a>
            </div>
            <div className="h-56px ml-16px pb-6px">
              <a target="_blank" href="https://www.facebook.com/lignumnostri/" className="">
                <div className="uppercase h-full flex text-16 justify-center items-center border text-lightGold border-lightGold p-10px mr-10px cursor-pointer">
                  <Icon name="fb-white" className="h-30px mt-2px mr-18px"/>   
                  facebook
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="justify-center items-center flex flex-col text-center mt-24px">
          <div className="w-full max-w-1440px m-auto">
              {data ?
                  <div className="w-full h-25w mobile:h-100w grid grid-cols-4 mobile:grid-cols-2 mobile:grid-rows-2 overflow-hidden">
                      {data?.slice(0, 4).map((image, i) => (
                          <DispImage image={image} i={i} rendered={rendered} setRendered={setRendered}/>
                      )
                      )}
                  </div>
                  :
                  <div className="grid grid-cols-4 grid-rows-1 mobile:grid-cols-2 mobile:grid-rows-2 relative h-360px w-full pt-24px">
                    <img src="/assets/img/socials1.png" className="object-cover absolute w-full h-full col-start-1 row-start-1 col-end-1 row-end-1 "/>
                    <img src="/assets/img/socials2.png" className="object-cover absolute w-full h-full col-start-2 row-start-1 col-end-2 row-end-1"/>
                    <img src="/assets/img/socials3.png" className="object-cover absolute w-full h-full col-start-3 row-start-1 col-end-3 row-end-1 mobile:col-start-1 mobile:row-start-2 mobile:col-end-1 mobile:row-end-2"/>
                    <img src="/assets/img/socials4.png" className="object-cover absolute w-full h-full col-start-4 row-start-1 mobile:col-start-2 mobile:row-start-2 mobile:col-end-2 mobile:row-end-2"/>
                  </div>
              }
          </div>
        </div>

        <div className="notMobile:hidden w-full text-darkGreen h-88px grid grid-cols-2 gap-16px">
          <div className="h-56px">
            <a target="_blank" href="https://www.instagram.com/lignumnostri/">
              <div className="uppercase 340:text-14 h-full flex text-16 items-center border text-lightGold border-lightGold p-10px ml-10px mt-15px mb-15px cursor-pointer">
                <Icon name="ig" className="h-30px 340:h-24px mr-10px 340:mr-4px"/>
                instagram
              </div>
            </a>
          </div>
            <div className="h-56px">
              <a target="_blank" href="https://www.facebook.com/lignumnostri/" className="">
                <div className="uppercase 340:text-14 h-full flex text-16 items-center text-lightGold border border-lightGold p-10px mr-10px mt-15px mb-15px cursor-pointer">
                  <Icon name="fb-white" className="h-30px 340:h-24px mr-10px 340:mr-6px flex 340:mt-0 mt-2px"/>
                  facebook
                </div>
              </a>
          </div>
        </div>
      </div>
    </>
  )
};