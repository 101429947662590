import { useState, createContext, useEffect } from "react";

export const ScreenContext = createContext({isMobile: false, checkMobile: ()=>{}, screenWidth: 0, played:false, setPlayed:(p:any)=>{}});

var rtime = new Date();
var timeout = false;
var delta = 200;

export function ScreenContextProvider(props) {

    let [isMobile, setMobile] = useState(true);
    let [width, setWidth] = useState(0);
    let [played, setPlayed] = useState(false)
  
    let checkMobile = () => {
        let isMob = window.innerWidth < 1150;
        setWidth(window.innerWidth);
        setMobile(isMob);
    }

    let resizeend = () => {
        // @ts-ignore
        if (new Date() - rtime < delta) {
            setTimeout(resizeend, delta);
        } else {
            timeout = false;
            checkMobile();
        }               
    }

    useEffect(()=>{
        checkMobile();
        window.addEventListener("resize", () => {
            rtime = new Date();
            if (timeout === false) {
                timeout = true;
                setTimeout(resizeend, delta);
            }
        })
    }, [])

    // useEffect(()=>{
    //     checkMobile();
    // }, [window.screen.width])
    
    return (
        <ScreenContext.Provider value={{isMobile: isMobile, checkMobile: checkMobile, screenWidth: width, played:played, setPlayed:setPlayed}} >
            {props.children}
        </ScreenContext.Provider>
    )
}