import React, { useState, useEffect, useRef } from 'react';
import Button from './Buttons/Button';
import ImgSvg from './ImgSvg';

export default function SawmillLanding(){

  return(
    <>
      <div className="notMobile:pt-100px notMobile:pb-70px relative mobile:py-16px ">
        <div className="flex justify-between mobile:flex-col-reverse mx-16px notMobile:mx-160px">
          <div className="flex flex-col max-w-lg">
            <div className="text-darkBrown text-h32 dmserif">
            Dřevěný nábytek vyrobený s&nbsp;láskou a úctou k&nbsp;přírodě.
            </div>
            <div className=" mt-24px mb-32px notMobile notMobile:w-80%">
            Jsme Lignum Nostri - dva lidé, které&nbsp;spojuje láska ke&nbsp;dřevu a&nbsp;poctivému řemeslu.
            </div>
            <div className="w-divider mobile:w-full border-t border-dividerGold "/>
            <div className="font-semibold mt-32px mb-16px">
            Ke&nbsp;každé zakázce přistupujeme individuálně. Získejte&nbsp;nabídku zdarma.
            </div>
            <Button href="#contact" onClick={(e)=> {e.preventDefault(); window.scrollTo({top: 2800 , behavior: "smooth"})}} className="uppercase w-221px bg-darkGreen border-darkGreen hover:text-white hover:bg-mediumGold hover:border-mediumGold">
              ZÍSKAT NABÍDKU
            </Button>
          </div>
          <ImgSvg src="sawmill" className="rounded-sm max-w-550px w-full 2560min:max-w-5xl mobile:max-w-2xl pr-10px notMobile:ml-16px mr-16px"/>
        </div>
      </div>
    </>
  )
};