import Layout from "components/Layouts/Layout";
import React, { useContext, useState } from "react";
import { ScreenContext } from "components/ScreenContext";
import { InertiaLink, useForm } from "@inertiajs/inertia-react";
import Modals, { MODALS, ModalsContext } from "app/components/Layouts/Modals";
import SelectProductType2 from "app/fragments/SelectProductType2";
import ProductsLanding from "app/components/ProductsLanding";
import PoraditSVyberem from "app/components/PoraditSVyberem";
import ZakazkovaVyroba2 from "app/components/ZakazkovaVyroba2";
import Svg from "app/components/Svg";
import Socials from "app/components/Socials";
import MilujemeVuniDreva from "app/components/MilujemeVuniDreva";



interface Props{
  className?: string,
}


export default function Home(props: Props) {
  const { isMobile } = useContext(ScreenContext);
  const {open} = useContext(ModalsContext)
  const form = useForm({});
  const {data} = form;
  return (
    <Layout>
      <div className="bg-bg w-full h-full">
        <div className="mobile:hidden relative w-full h-full">
          <Svg src="/icons/loglines5" className="mt-280px absolute left-0 top-0 z-0"/>
        </div>
        <ProductsLanding />
        <SelectProductType2 />
        <PoraditSVyberem />
        <ZakazkovaVyroba2 />
        <Socials />
      </div>
    </Layout>
  );
}