import { InertiaLink, useForm, } from '@inertiajs/inertia-react'
import React, { useContext, useState } from 'react'
import Icon from '../Icon';
import Svg from '../Svg';
import { MODALS, ModalsContext } from './Modals';

export interface HeaderProps {
    products?:any
}

function Header(props: HeaderProps) {
    const {products} = props

    const form = useForm({ lang: 'cs' });
    const {open} = useContext(ModalsContext)
    const { data } = form;
    let url = window.location.pathname
    console.log(url)
    return (
        <>
            <header className={((url=="/vyroba")?"bg-darkBrown ":"bg-darkGreen ") + ("w-full h-72px text-dividerGold sticky top-0 z-99999")}>
                <div className=" relative mx-160px h-72px flex justify-between">
                    <div className="flex justify-self-start pt-24px z-50">
                        <InertiaLink href="/produkty" className="mr-80px">
                            <div className=" relative">
                                <div className="">Produkty</div>
                                <Svg src="/icons/produktyunderline" className={(url=="/produkty")?" ":"hidden "}/>
                                <Svg src="/icons/produktyunderline" className={(url=="/product")?" ":"hidden "}/>
                            </div>
                        </InertiaLink>
                        <InertiaLink href="/vyroba" >
                            <div className="">
                                <div className="">Zakázková výroba</div>
                                <Svg src="/icons/vyrobaunderline" className={((url=="/vyroba")?" ":" hidden ") + ("")}/>
                            </div>
                        </InertiaLink>
                    </div>

                    <div className="absolute w-full items-center flex justify-center">
                        <InertiaLink href="/" className=" h-full mt-16px">
                            <Icon name="logo" className="h-40px" />
                        </InertiaLink>
                    </div>

                    <div className="flex justify-self-end pt-24px z-50">
                        <InertiaLink href="/pribeh">
                            <div>
                                <div className="">Příběh</div>
                                <Svg src="/icons/pribehunderline" className={(url=="/pribeh")?" ":"hidden "}/>
                            </div>
                        </InertiaLink>
                        <InertiaLink href="/kontakt" className="ml-80px">
                            <div>
                                <div className="">Kontakt</div>
                                <Svg src="/icons/kontaktunderline" className={(url=="/kontakt")?" ":"hidden "}/>
                            </div>
                    </InertiaLink>
                    </div> 
                </div>
            </header>
        </>
    )
}

export default Header

interface HLProps {
    href: string
    text: string
    active?: boolean
}

function HeaderLink({ href, text, active = false }: HLProps) {
    return <InertiaLink href={href} className={(" transform duration-300 ml-28px py-28px border-darkBrandColor text-darkBrandColor whitespace-nowrap ") + (active?"text-lightBlue":"")}>{text}</InertiaLink>
}