import { useForm } from '@inertiajs/inertia-react'
import Form from 'app/fragments/Forms/Form'
import PasswordField from 'app/fragments/Forms/PasswordField'
import Submit from 'app/fragments/Forms/Submit'
import TextField from 'app/fragments/Forms/TextField'
import React from 'react'
import route from 'ziggy-js'

interface Props {}

function Login(props: Props) {
    const {} = props
    const form = useForm({});
    const {data, post} = form;

    const submit = (e) => {
        e.preventDefault();
        post(route('login'), {
            preserveScroll: true,
            preserveState: true
        });
    }

    return (
        <div className="flex justify-center py-85px">
            <Form form={form} onSubmit={submit} className="max-w-xl">
                <TextField name="email" label="Email"/>
                <PasswordField name="password" label="Password" />
                <Submit value="Log In" />
            </Form>
        </div>
    )
}

export default Login
